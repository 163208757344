import { LoadingCellRenderer } from '@ag-grid-community/core/dist/cjs/es5/rendering/cellRenderers/loadingCellRenderer';
import React, { useEffect, useState } from 'react'
import { Oval, RotatingLines } from 'react-loader-spinner';
import { FetchSubscription, FetchSubscriptions } from '../../../utils/api/requests';
import { config } from '../../../utils/config';
import SubscriptionCard from './../../../components/SubscriptionCard';
import Currency from 'react-currency-formatter';
import ReactDOMServer from 'react-dom/server';
import { Link } from 'react-router-dom';

function SubscriptionsList() {
    const [subscriptions, setSubscriptions] = useState([]),
        [loading, setLoading] = useState(true),
        [selectedSubscription, setSelectedSubscription] = useState([]),
        [fetching, setFetching] = useState(false);

    const getSubscriptions = async () => {
        try {
            const { data } = await FetchSubscriptions();

            setSubscriptions(data);

            console.log(data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    window.selectSubscription = async (subscription) => {
        setSelectedSubscription(null);

        try {
            setFetching(true);

            const { data } = await FetchSubscription(subscription);

            setSelectedSubscription(data);
            console.log(data);
        } catch (error) {
            setSelectedSubscription(null);
        } finally {
            setFetching(false);
        }
    }

    const tableContent = () => {
        const dt_invoice_table = window.$('.invoice-list-table');
        if (dt_invoice_table.length) {
            const dt_invoice = dt_invoice_table.DataTable({
                data: subscriptions,
                "bDestroy": true,

                columns: [
                    { data: '' },
                    { data: 'company' },
                    { data: 'start_date' },
                    { data: 'end_date' },
                    { data: 'amount' },
                    { data: 'plan' },
                    { data: 'status' },
                    {},
                ],
                columnDefs: [
                    {
                        // For Responsive
                        className: 'control',
                        responsivePriority: 2,
                        searchable: false,
                        orderable: false,
                        targets: 0,
                        render: function (data, type, full, meta) {
                            return '';
                        }
                    },
                    {
                        targets: 1,
                        render: function (data, type, full, meta) {
                            return `<div class="d-flex justify-content-start align-items-center">
								<div class="d-flex flex-column">
								<span>${full.company?.name}</span>
								</div>
								</div>`;
                        }
                    },
                    {
                        targets: 2,
                        render: function (data, type, full, meta) {
                            return `<div class="d-flex justify-content-start align-items-center">
								<div class="d-flex flex-column">
								<span>${full.start_date}</span>
								</div>
								</div>`;
                        }
                    },
                    {
                        targets: 3,
                        render: function (data, type, full, meta) {
                            return `<div class="d-flex justify-content-start align-items-center">
								<div class="d-flex flex-column">
								<span>${full.end_date}</span>
								</div>
								</div>`;
                        }
                    },
                    {
                        targets: 4,
                        render: function (data, type, full, meta) {
                            return `<div class="d-flex justify-content-start align-items-center">
								<div class="d-flex flex-column">
								<span>${ReactDOMServer.renderToStaticMarkup(<Currency quantity={full.formatted_amount} currency={full.currency} />)}</span>
								</div>
								</div>`;
                        }
                    },
                    {
                        targets: 5,
                        render: function (data, type, full, meta) {
                            return `<div class="d-flex justify-content-start align-items-center">
								<div class="d-flex flex-column">
								<span>${full.plan}</span>
								</div>
								</div>`;
                        }
                    },
                    {
                        targets: 6,
                        render: function (data, type, full, meta) {
                            return `<div class="d-flex justify-content-start align-items-center">
								<div class="d-flex flex-column">
								<span class="badge rounded-pill ${full?.status == 'expired' ? 'bg-label-danger' : full?.status == 'pending' ? 'bg-label-warning' : 'bg-label-success'}">${full.status}</span>
								</div>
								</div>`;
                        }
                    },
                    {
                        // Actions
                        targets: -1,
                        title: 'Actions',
                        searchable: false,
                        orderable: false,
                        render: function (data, type, full, meta) {
                            return (
                                `<div class="d-flex align-items-center">
								<a href="javascript:void(0);" class="text-body" data-bs-placement="top" data-bs-toggle="offcanvas" data-bs-target="#offcanvasViewUser" onclick="selectSubscription('${full.id}')"><i class="bx bx-show mx-1"></i></a>
								 </div>`
                            );
                        }
                    }
                ],
                
                dom:
                    '<"row ms-2 me-3"' +
                    '<"col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start gap-2"l<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start mt-md-0 mt-3"B>>' +
                    '<"col-12 col-md-6 d-flex align-items-center justify-content-end flex-column flex-md-row pe-3 gap-md-2"f<"invoice_status mb-3 mb-md-0">>' +
                    '>t' +
                    '<"row mx-2"' +
                    '<"col-sm-12 col-md-6"i>' +
                    '<"col-sm-12 col-md-6"p>' +
                    '>',
                language: {
                    sLengthMenu: '_MENU_',
                    search: '',
                    searchPlaceholder: 'Search companies'
                },
                responsive: {
                    details: {
                        display: window.$.fn.dataTable.Responsive.display.modal({
                            header: function (row) {
                                var data = row.data();
                                return 'Details of Asset ID ' + data['asset_id'];
                            }
                        }),
                        type: 'column',
                        renderer: function (api, rowIdx, columns) {
                            var data = window.$.map(columns, function (col, i) {
                                return col.title !== '' // ? Do not show row in modal popup if title is blank (for check box)
                                    ? '<tr data-dt-row="' +
                                    col.rowIndex +
                                    '" data-dt-column="' +
                                    col.columnIndex +
                                    '">' +
                                    '<td>' +
                                    col.title +
                                    ':' +
                                    '</td> ' +
                                    '<td>' +
                                    col.data +
                                    '</td>' +
                                    '</tr>'
                                    : '';
                            }).join('');

                            return data ? window.$('<table class="table"/><tbody />').append(data) : false;
                        }
                    }
                },

            });
        }

        // On each datatable draw, initialize tooltip
        dt_invoice_table.on('draw.dt', function () {
            var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
            var tooltipList = tooltipTriggerList?.map(function (tooltipTriggerEl) {
                return new window.bootstrap.Tooltip(tooltipTriggerEl, {
                    boundary: document.body
                });
            });
        });

        setTimeout(() => {
            window.$('.dataTables_filter .form-control').removeClass('form-control-sm');
            window.$('.dataTables_length .form-select').removeClass('form-select-sm');
        }, 300);
    }

    useEffect(() => {
        getSubscriptions();
    }, []);

    useEffect(() => {
        tableContent();
    }, [subscriptions]);

    if (loading) {
        return (
            <center><RotatingLines width="35" strokeColor="#6495ED" strokeWidth="3" /></center>
        )
    }

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="breadcrumb-wrapper py-3 mb-4">
                Subscriptions
            </h4>
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-4">
                        <div class="card-header d-flex justify-content-between align-items-center">
                            <div className='container-fluid' style={{padding: '0px'}}>
                                <div class='row'>
                                    <div class='col-md-6'>
                                        <h5 class="mb-0">Subscription History</h5>
                                    </div>
                                    <div class='col-md-6'>
                                        <Link to="create" style={{float: 'right'}} class='btn btn-primary'>New subscription</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='card-body'>
                            <div class="card-datatable table-responsive">
                                <table class="invoice-list-table table table-bordered" id="view_users_table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>COMPANY</th>
                                            <th>START DATE</th>
                                            <th>END DATE</th>
                                            <th>AMOUNT</th>
                                            <th>PLAN</th>
                                            <th>STATUS</th>
                                            <th>ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="offcanvas offcanvas-end"
                    tabindex="-1"
                    id="offcanvasViewUser"
                    aria-labelledby="offcanvasAddViewLabel"
                >
                    <div class="offcanvas-header border-bottom">
                        <h6 id="offcanvasAddViewLabel" class="offcanvas-title">{selectedSubscription?.company?.name}</h6>
                        <button
                            type="button"
                            class="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="offcanvas-body flex-grow-0 mx-0">
                        {fetching && <Oval width="25" strokeColor={config.colors.primary} strokeWidth="3" />}

                        {!fetching && <>
                            <div className='row'>
                                <SubscriptionCard label="Subscription Value" value={(<Currency quantity={selectedSubscription?.formatted_amount} currency={selectedSubscription?.currency} />)} />
                                <SubscriptionCard label="Invoice Number" value={selectedSubscription.code} />
                            </div>
                            <table className='table'>
                                <tbody>
                                    <tr>
                                        <td>Start Date</td>
                                        <td>{selectedSubscription?.start_date}</td>
                                    </tr>
                                    <tr>
                                        <td>End Date</td>
                                        <td>{selectedSubscription?.end_date}</td>
                                    </tr>
                                    <tr>
                                        <td>Payment Method</td>
                                        <td>Card</td>
                                    </tr>
                                    <tr>
                                        <td>Intiatied By</td>
                                        <td>Gbenga Bisi</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td>
                                            <div class="d-flex justify-content-start align-items-center">
                                                <div class="d-flex flex-column">
                                                    <span class={`badge rounded-pill ${selectedSubscription?.status == 'expired' ? 'bg-label-danger' : selectedSubscription?.status == 'pending' ? 'bg-label-warning' : 'bg-label-success'}`}>{selectedSubscription?.status}</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </>}
                    </div>
                </div>

            </div>
        </div >
    )
}

export default SubscriptionsList