import logo from "../../assets/img/app_logo.png"
import reg_Aside from '../../assets/img/components/reg_Aside.png'
import { useNavigate, useLocation } from "react-router-dom";
import { URL } from "../../utils/AppParams";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import "../../assets/css/verify.css"
import OtpInputGrid from "../../components/OtpInputGrid";
import ImageFlexContainer from "../../components/ImageFlexContainer";
import { VerifyPasswordResetCode } from "../../utils/api/requests";

const VerifyResetCode = () => {
    const PIN_LENGTH = 6;
    const navigate = useNavigate();
    const location = useLocation();
    const [showSpinner, setShowSpinner] = useState(false);
    const [email, setEmail] = useState('');

    const [pin, setPin] = useState(
        new Array(PIN_LENGTH)
    );

    const onPinChanged = (pinEntry, index) => {
        const newPin = [...pin];
        newPin[index] = pinEntry;
        setPin(newPin);
    };

    useEffect(() => {
        setEmail(location.state?.email);
    }, []);

    const submit = async (e) => {
        try {
            e.preventDefault();

            const otp = pin.join('')

            if (otp.length < 6) {
                toast.error('OTP fields must be completed');
                return;
            }

            setShowSpinner(true);

            const {message} = await VerifyPasswordResetCode({ email: location.state.email, "otp": otp });
              
            navigate('/reset-password', { state: { email: location.state.email, otp }, replace: true });
        } catch (error) {
            toast.error(error.message);
        }finally{
            setShowSpinner(false);
        }
    }

    return (
        <ImageFlexContainer img={reg_Aside} verify={true}>
            <div className="verify_container">
                <div class="card-body">

                    <div className="verify_app-brand justify-content-center">
                        <a href="index.html" className="verify_app-brand-link gap-2">
                            <img src={logo} className="verify_brand_logo" />
                        </a>
                    </div>

                    <center><h4 className="mb-2 mt-3 h4">Password Reset</h4></center>
                    <p className="text-start mb-4 custom_p">
                        We sent a reset code to your email address. Enter the code from the email address in the field below.
                        <span class="d-block fw-bold mt-2">{email}</span>
                    </p>
                    <p class="fw-semibold mt-4 mb-1">Type your 6 digit security code</p>
                    <form id="twoStepsForm" action="" method="POST" onSubmit={submit}>
                        <div class="mb-3">
                            <div
                                class="auth-input-wrapper numeral-mask-wrapper d-flex justify-content-sm-between align-items-center"
                            >
                                <OtpInputGrid
                                    onPinChanged={onPinChanged}
                                    pin={pin}
                                    pinLength={PIN_LENGTH}
                                />
                            </div>

                        </div>

                        {!showSpinner && <button class="btn btn-primary d-grid w-100 mb-3" id="submit">Continue</button>}
                        {showSpinner && <center><RotatingLines width="35" strokeColor="#6495ED" strokeWidth="3" /></center>}

                        <div class="text-center">
                            Didn't get the code?
                            <a href="javascript:void(0);" id="resend" > Resend </a>
                        </div>
                    </form>
                </div>
            </div>
        </ImageFlexContainer>
    );
}

export default VerifyResetCode;