import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AddFeature, FetchSubscriptionPlans, FetchSubscriptionPlansFeatures } from '../../../utils/api/requests';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Modal, Button } from 'react-bootstrap';
import InputField from '../../../components/InputField';
import joi from 'joi-browser';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Features = () => {
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState({});

    const [featureData, setFeatureData] = useState({
        featureName: '',
    })
    const { featureName } = featureData;

    const [rowData, setRowData] = useState([
    ]);

    const [columnDefs] = useState([
        { field: 'sn', headerName: '#' },
        { field: 'name', headerName: 'Name' },
        { field: 'actions', headerName: 'Actions' }
    ])

    const fetchFeatures = async () => {
        const { features } = await FetchSubscriptionPlansFeatures();

        renderFeatures(features);
    }

    const renderFeatures = (features) => {
        let sn = 0;
        let temp = features.map(feature => {
            sn++;

            return {
                sn,
                name: feature.name,
                actions: ''
            }
        });

        setRowData(temp);
    }

    useEffect(() => {
        fetchFeatures();

    }, []);

    // Validate featureSchema
    const featureSchema = {
        featureName: joi.string().required().label("Feature"),
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFeatureChange = (e) => {
        const { name, value } = e.target;

        const temp = { ...featureData };
        temp[name] = value;

        setFeatureData(temp);
    }

    const validateFeature = (state, objSchema) => {
        const options = { abortEarly: false };
        const { error } = joi.validate(state, objSchema, options);

        const errors = {};

        if (error) {
            for (let item of error.details) errors[item.path[0]] = item.message;
            setErrors(errors);
        }

        return error ? errors : null;
    }

    const handleAddFeature = async () => {
        try {
            const isValid = validateFeature(featureData, featureSchema);
            if (isValid) return;

            const { message, features } = await AddFeature({ featureName });

            toast.success(message);

            renderFeatures(features);

            setFeatureData({
                featureName: '',
            });

        } catch (error) {
            toast.error(error);
        } finally{
            setShow(false);
        }

    }

    return (
        <div class="row p-3">
            <div class="col-md-12">
                <div class="card mb-4">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <div className='col-md-6'>
                            <h5 class="mb-0">Subscription Features</h5>
                        </div>
                        <div className='col-md-6'>
                            <Link to='#' onClick={handleShow} className='btn btn-outline-primary btn-sm float-right ml-1' style={{ float: 'right' }}>Add Feature</Link>
                        </div>
                    </div>

                    <div className='card-body'>
                        <div className="ag-theme-alpine" style={{ height: '400px', width: 'auto' }}>
                            <AgGridReact
                                rowData={rowData}
                                columnDefs={columnDefs}
                                animateRows={true}>

                            </AgGridReact>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Feature</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <InputField
                        defaultInput={true}
                        name="featureName"
                        label="Feature:"
                        inputType="text"
                        id="featureName"
                        value={featureName}
                        placeholder="Enter a feature here..."
                        handleChange={handleFeatureChange}
                        error={errors.featureName}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleAddFeature}>
                        Submit
                    </Button>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default Features;