import React, { useEffect, useState } from 'react'
import RoundedFileInput from '../../../components/RoundedFileInput'
import profileBanner from "../../../assets/img/profileBanner.svg";
import { Tab, Tabs } from 'react-bootstrap';
import CompanyUsers from '../../../components/CompanyUsers';
import CompanyInsurance from '../../../components/CompanyInsurance';
import CompanySubscriptions from '../../../components/CompanySubscriptions';
import SelectedCompanyDetails from '../../../components/CompanyDetails';
import { FetchCompanyDetails } from '../../../utils/api/requests';
import { RotatingLines } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';

function CompanyDetails() {
    const [loading, setLoading] = useState(true);
    const [company, setCompany] = useState([]),
        [companyUsers, setCompanyUsers] = useState([]),
        [companySubscriptions, setCompanySubscriptions] = useState([]),
        [analytics, setAnalytics] = useState([]);

    const { companyId } = useParams();

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        telephone: '',
        picture: '',
        email: ''

    });

    const getCompanyDetails = async () => {
        try {
            const { data } = await FetchCompanyDetails({ companyId });

            setCompany(data.company);
            setCompanyUsers(data.users);
            setCompanySubscriptions(data.subscriptions);
            setAnalytics(data.analytics);

            console.log(data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getCompanyDetails();
    }, []);

    if (loading) {
        return (
            <center><RotatingLines width="35" strokeColor="#6495ED" strokeWidth="3" /></center>
        )
    }
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="breadcrumb-wrapper py-3 mb-4">
                <span className="text-muted fw-light">Companies /</span> {company.name}
            </h4>

            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="user-profile-header-banner">
                            <img src={profileBanner} alt="Banner_image" className="rounded-top" style={{ width: '100%' }} />
                        </div>
                        <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-4 px-4 py-3">
                            <div className=" mt-n2 flex-shrink-0 mx-auto mx-sm-0">
                                <RoundedFileInput
                                    avatar={`A`}
                                    picture={undefined}
                                    formData={formData}
                                    setFormData={setFormData}
                                    indicator={false}
                                />
                            </div>
                            <div className="flex-grow-1 mt-3 mt-sm-5">
                                <div
                                    className="gap-4 d-flex flex-column flex-md-row justify-content-start justify-content-md-between align-items-center align-items-sm-start align-items-md-end mx-4"
                                >
                                    <div className="user-profile-info">
                                        <h4>{company.name}</h4>
                                        <ul
                                            className="list-inline gap-2 d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center mb-0"
                                        >
                                            <li className="list-inline-item fw-semibold"><i className="bx bx-phone"></i> {company.telephone}</li>
                                            <li className="list-inline-item fw-semibold"><i className="bx bx-map"></i> {company.address}</li>
                                            <li className="list-inline-item fw-semibold">
                                                <i className="bx bx-calendar-alt"></i> Joined {company.created_at}
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='px-4'>
                            <ul class="nav nav-tabs tabs-line" role="tablist">
                                <li class="nav-item">
                                    <button
                                        type="button"
                                        class="nav-link active"
                                        role="tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#tab_company_details"
                                        aria-controls="tab_company_details"
                                        aria-selected="true"
                                    >
                                        Company Details
                                    </button>
                                </li>
                                <li class="nav-item">
                                    <button
                                        type="button"
                                        class="nav-link"
                                        role="tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#tab_company_users"
                                        aria-controls="tab_company_users"
                                        aria-selected="false"
                                    >
                                        Users
                                    </button>
                                </li>
                                <li class="nav-item">
                                    <button
                                        type="button"
                                        class="nav-link"
                                        role="tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#tab_company_insurance"
                                        aria-controls="tab_company_insurance"
                                        aria-selected="false"
                                    >
                                        Insurance
                                    </button>
                                </li>
                                <li class="nav-item">
                                    <button
                                        type="button"
                                        class="nav-link"
                                        role="tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#tab_company_subscriptions"
                                        aria-controls="tab_company_subscriptions"
                                        aria-selected="false"
                                    >
                                        Subscriptions
                                    </button>
                                </li>

                            </ul>
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="tab_company_details" role="tabpanel">
                                    <SelectedCompanyDetails company={company} analytics={analytics} />
                                </div>
                                <div class="tab-pane fade" id="tab_company_users" role="tabpanel">
                                    <CompanyUsers users={companyUsers} />
                                </div>
                                <div class="tab-pane fade" id="tab_company_insurance" role="tabpanel">
                                    <CompanyInsurance />
                                </div>
                                <div class="tab-pane fade" id="tab_company_subscriptions" role="tabpanel">
                                    <CompanySubscriptions subscriptions={companySubscriptions} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CompanyDetails