import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { CreateUserAccount, DeleteUser, FetchSingleUser, FetchUsers, SuspendStaff, UnsuspendStaff } from '../../../utils/api/requests';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';
import dateHelper from 'date-helper-util';

const ViewUsers = () => {
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const [viewUser, setViewUser] = useState({});

	let infoBox = useRef(null);

	const inviteUser = async () => {
		try {
			setLoading(true);

			const { message, data } = await CreateUserAccount({
				fullname: formik.values.fullname,
				email: formik.values.email,
				role: formik.values.role
			});

			toast.success(message);

			setUsers(data);

			formik.values.email = ''
			formik.values.fullname = ''
			formik.values.role = '';

		} catch (error) {
			console.log(error);
			toast.error(error.message)
		} finally {
			setLoading(false);
		}
	}

	const schema = yup.object().shape({
		fullname: yup.string().required('Fullname is required'),
		email: yup.string().email("Provide a valid email").required('Email is required'),
		role: yup.string().required('Role is required'),
	});

	const formik = useFormik({
		initialValues: {
			fullname: '',
			email: '',
			role: '',
		},
		validationSchema: schema,
		onSubmit: inviteUser
	})

	const fetchAllUsers = async () => {
		const { data } = await FetchUsers();

		setUsers(data);
	}

	const fetchUser = async (invitationId) => {
		try {
			setLoading(true);

			const { data } = await FetchSingleUser(invitationId);

			setViewUser(data);
		} catch (error) {

		} finally {
			setLoading(false);
		}
	}

	const deleteUser = async (invitationId) => {
		try {
			setLoading(true);

			const { data, message } = await DeleteUser(invitationId);

			toast.success(message);

			setUsers(data);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	}

	const suspendStaff = async (invitationId) => {
		infoBox.current = toast.loading("Processing request, please wait...");

		try {
			const { data, message } = await SuspendStaff(invitationId);

			toast.update(infoBox.current, { render: message, type: "success", isLoading: false, autoClose: true, hideProgressBar: false, closeOnClick: true, progress: undefined });

			setUsers(data);
		} catch (error) {
			console.log(error);
			toast.update(infoBox.current, { render: error.message, type: "error", isLoading: false, autoClose: true, hideProgressBar: false, closeOnClick: true, progress: undefined });
		}
	}

	const unsuspendStaff = async (invitationId) => {
		infoBox.current = toast.loading("Processing request, please wait...");

		try {
			const { data, message } = await UnsuspendStaff(invitationId);

			toast.update(infoBox.current, { render: message, type: "success", isLoading: false, autoClose: true, hideProgressBar: false, closeOnClick: true, progress: undefined });
			
			setUsers(data);
		} catch (error) {
			console.log(error);
			toast.update(infoBox.current, { render: error.message, type: "error", isLoading: false, autoClose: true, hideProgressBar: false, closeOnClick: true, progress: undefined });
		}
	}

	useEffect(() => {
		fetchAllUsers();

	}, []);

	return (
		<div className="container-xxl flex-grow-1 container-p-y">
			<h4 className="breadcrumb-wrapper py-3 mb-4">
				Users
			</h4>
			<div class="row">
				<div class="col-md-12">
					<div class="card mb-4">
						<div class="card-header d-flex justify-content-between align-items-center">
							<h5 class="mb-0">Users</h5>
							<button
								class="dt-button add-new btn btn-primary"
								tabindex="0"
								aria-controls="DataTables_Table_0"
								type="button"
								data-bs-toggle="offcanvas"
								data-bs-target="#offcanvasAddUser"
							><span><i class="bx bx-plus me-0 me-lg-2"></i><span class="d-none d-lg-inline-block">Add User</span></span>
							</button>
						</div>

						<div className='card-body'>
							<div class="card-datatable table-responsive">
								<table class="dt-fixedheader table table-bordered" id="view_users_table">
									<thead>
										<tr>
											<th>USER</th>
											<th>EMAIL</th>
											<th>ROLE</th>
											<th>ACCOUNT CREATED</th>
											<th>LAST LOGIN DATE</th>
											<th>ACCOUNT STATUS</th>
											<th>ACTIONS</th>
										</tr>
									</thead>
									<tbody>
										{

											users.map(user => <tr key={user.id}>
												<td>{`${user.fullname}`}</td>
												<td>{user.email}</td>
												<td>{user.role}</td>
												<td>{dateHelper(new Date(user.date)).getFormated("DD/MM/YYYY")}</td>
												<td></td>
												<td>{user.status}</td>
												<td>
													<button className='btn btn-primary btn-sm mb-1' data-bs-toggle="offcanvas" data-bs-target="#offcanvasViewUser" onClick={() => fetchUser(user.id)}>View</button>
													<button className='btn btn-danger btn-sm' onClick={() => deleteUser(user.id)}>Remove</button>
													{
														user.status.toLowerCase() == 'accepted' && <button className='btn btn-warning btn-sm' onClick={() => suspendStaff(user.id)}>Suspend</button>
													}
													{
														user.status.toLowerCase() == 'suspended' && <button className='btn btn-success btn-sm' onClick={() => unsuspendStaff(user.id)}>Unsuspend</button>
													}
												</td>
											</tr>
											)
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>


				<div
					class="offcanvas offcanvas-end"
					tabindex="-1"
					id="offcanvasAddUser"
					aria-labelledby="offcanvasAddUserLabel"
				>
					<div class="offcanvas-header border-bottom">
						<h6 id="offcanvasAddUserLabel" class="offcanvas-title">Add User</h6>
						<button
							type="button"
							class="btn-close text-reset"
							data-bs-dismiss="offcanvas"
							aria-label="Close"
						></button>
					</div>
					<div class="offcanvas-body flex-grow-0 mx-0">
						<form class="add-new-user pt-0" id="addNewUserForm" onSubmit={formik.handleSubmit} method="post">
							<div class="mb-3">
								<label class="form-label" for="fullname">Full Name</label>
								<input
									type="text"
									className={`form-control ${(formik.errors.fullname && formik.touched.fullname) ? 'input-error' : ''}`}
									id="fullname"
									placeholder="Full Name"
									name="fullname"
									value={formik.values.fullname}
									onChange={formik.handleChange}
									aria-label="Full Name"
								/>
								{(formik.errors.fullname && formik.touched.fullname) ? <p className='error'>{formik.errors.fullname}</p> : ''}
							</div>
							<div class="mb-3">
								<label class="form-label" for="email">Email</label>
								<input
									type="text"
									id="email"
									className={`form-control ${(formik.errors.email && formik.touched.email) ? 'input-error' : ''}`}
									placeholder="Email"
									aria-label="Email"
									name="email"
									value={formik.values.emal}
									onChange={formik.handleChange}
								/>
								{(formik.errors.email && formik.touched.email) ? <p className='error'>{formik.errors.email}</p> : ''}
							</div>

							<div class="mb-3">
								<label class="form-label" for="role">Role</label>
								<select
									id="role"
									className={`form-select ${(formik.errors.role && formik.touched.role) ? 'input-error' : ''}`}
									name='role'
									value={formik.values.role}
									onChange={formik.handleChange}
								>
									<option value="" disabled selected>Please select</option>
									<option value="admin">Admin</option>
								</select>
								{(formik.errors.role && formik.touched.role) ? <p className='error'>{formik.errors.role}</p> : ''}
							</div>
							{loading && <Oval width="25" strokeColor="#6495ED" strokeWidth="3" />}
							{!loading && <button type="submit" class="btn btn-primary data-submit me-1 me-sm-3">Submit</button>}
							{!loading && <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="offcanvas">Cancel</button>}
						</form>
					</div>
				</div>

				<div
					class="offcanvas offcanvas-end"
					tabindex="-1"
					id="offcanvasViewUser"
					aria-labelledby="offcanvasAddViewLabel"
				>
					<div class="offcanvas-header border-bottom">
						<h6 id="offcanvasAddViewLabel" class="offcanvas-title">View User</h6>
						<button
							type="button"
							class="btn-close text-reset"
							data-bs-dismiss="offcanvas"
							aria-label="Close"
						></button>
					</div>
					<div class="offcanvas-body flex-grow-0 mx-0">
						<form class="add-new-user pt-0" id="addNewUserForm" method="post">
							<div class="mb-3">
								<label class="form-label" for="fullname">Full Name</label>
								<input
									type="text"
									className='form-control'
									id="fullname"
									placeholder="Full Name"
									name="fullname"
									value={viewUser.fullname}
									aria-label="Full Name"
								/>
							</div>
							<div class="mb-3">
								<label class="form-label" for="email">Email</label>
								<input
									type="text"
									className='form-control'
									id="email"
									placeholder="Email"
									aria-label="Email"
									name="email"
									value={viewUser.email}
								/>
							</div>

							<div class="mb-3">
								<label class="form-label" for="role">Role</label>
								<select
									className='form-select'
									id="role"
									name='role'
									value={viewUser.role}
								>
									<option value="" disabled selected>Please select</option>
									<option value="admin">Admin</option>
								</select>
							</div>
							{loading && <Oval width="25" strokeColor="#6495ED" strokeWidth="3" />}

						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ViewUsers;