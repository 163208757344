import api from "../axios";

/**
 * get all app users
 * @returns {Promise} that will resolve to object or an error object if the supplied credentials are not valid
 */

export const FetchUsers = async () => {
  try {
    const { data } = await api.get(`/admin/users`);

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const FetchSingleUser = async (invitationId) => {
  try {
    const { data } = await api.get(`/admin/users/${invitationId}`);

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const CreateUserAccount = async ({fullname, email, role}) => {
  try {
    const { data } = await api.post(`/admin/users`, {fullname, email, role});

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const DeleteUser = async (invitationId) => {
  try {
    const { data } = await api.delete(`/admin/users/${invitationId}`);

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const SuspendStaff = async (invitationId) => {
  try {
    const { data } = await api.post(`/admin/users/${invitationId}/suspension`);

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const UnsuspendStaff = async (invitationId) => {
  try {
    const { data } = await api.delete(`/admin/users/${invitationId}/suspension`);
    
    return data;
  } catch (error) {
    throw error.response.data;
  }
}


export const FetchCompanies = async () => {
  try {
    const { data } = await api.get(`/admin/companies`);

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const FetchCompanyDetails = async ({companyId}) => {
  try {
    const { data } = await api.get(`/admin/companies/${companyId}`);

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const FetchCompanyUsers = async ({companyId}) => {
  try {
    const { data } = await api.get(`/admin/companies/${companyId}/users`);

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const FetchSubscriptions = async () => {
  try {
    const { data } = await api.get(`/admin/subscriptions`);

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const FetchSubscription = async (subscriptionId) => {
  try {
    const { data } = await api.get(`/admin/subscriptions/${subscriptionId}`);

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const FetchPayments = async () => {
  try {
    const { data } = await api.get(`/admin/payments`);

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const FetchPayment = async (paymentId) => {
  try {
    const { data } = await api.get(`/admin/payments/${paymentId}`);

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const FetchAssetTypes = async () => {
  try {
    const { data } = await api.get(`/admin/asset-types`);

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const AddAssetType = async ({ asset_type }) => {
  try {
    const { data } = await api.post(`/admin/asset-types`, { asset_type });
    
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const UpdateAssetTypeStatus = async ({asset_type, action}) => {
  try {
    const { data } = await api.patch(`/admin/asset-types/${asset_type}?status=${action}`);

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const UpdateAssetType = async ({assetTypeId, assetType}) => {
  try {
    const { data } = await api.put(`/admin/asset-types/${assetTypeId}`, {asset_type: assetType});

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const DeleteAssetType = async ({assetTypeId}) => {
  try {
    const { data } = await api.delete(`/admin/asset-types/${assetTypeId}}`);

    return data;
  } catch (error) {
    throw error.response.data;
  }
}


export const FetchSubscriptionPlans = async () => {
  try {
    const { data } = await api.get(`/admin/subscription-plans`);

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const CreateSubscriptionRequest = async (postData) => {
  try {
    const { data } = await api.post(`/admin/subscriptions`, postData);
    
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

/**
 * add a new subscription feature
 * @param {{ featureName: string }} credentials data to add a new feature
 * @returns {Promise} that will resolve to a new object or an error object if the supplied credentials are not valid
 */
export const AddFeature = async ({ featureName }) => {
  try {
    const { data } = await api.post(`/dashboard/subscriptions/features`, { feature: featureName });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}


/**
 * get all subscription plans features
 * @returns {Promise} that will resolve to object or an error object if the supplied credentials are not valid
 */

export const FetchSubscriptionPlansFeatures = async () => {
  try {
    const { data } = await api.get(`/dashboard/subscriptions/features`);

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

/**
* add a new subscription plan
* @param {{ planName: string }} credentials data to add a new feature
* @returns {Promise} that will resolve to a new object or an error object if the supplied credentials are not valid
*/
export const AddSubscriptionPlan = async ({ name, type, monthly, annually, features }) => {
  try {
    const { data } = await api.post(`/dashboard/subscriptions/plans`, { name, type, monthly, annually, features });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}


export const ValidateInvitation = async ({ invitationCode }) => {
  try {
    const { data } = await api.get(`/admin/user-invitation/${invitationCode}`);

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const AcceptInvitation = async (invitationCode, credentials) => {
  try {
    const { data } = await api.put(`/admin/user-invitation/${invitationCode}`, {...credentials });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const ForgotPasswordRequest = async (credentials) => {
  try {
    const { data } = await api.post(`/password-reset-codes`, {...credentials });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const VerifyPasswordResetCode = async (credentials) => {
  try {
    const { data } = await api.post(`/password-reset-codes/verification`, {...credentials });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const ResetUserPassword = async (credentials) => {
  try {
    const { data } = await api.put(`/password`, {...credentials });

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export const GetAnalyticsData = async () => {
  try {
    const { data } = await api.get(`/admin/analytics`);

    return data;
  } catch (error) {
    throw error.response.data;
  }
}