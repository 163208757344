import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { RotatingLines } from 'react-loader-spinner';
import { Link, useNavigate, useParams } from 'react-router-dom';
import logo_white from './../../assets/img/logo_white.svg';
import PasswordChecklist from 'react-password-checklist';
import { toast } from 'react-toastify';
import joi from "joi-browser";
import InputField from '../../components/InputField';
import CustomTelInput from "../../components/CustomTelInput";
import { AcceptInvitation, ValidateInvitation } from '../../utils/api/requests';
import invitation from "../../assets/img/invitation.png";
import logo_dark from '../../assets/img/logo-dark1.svg'
import '../../assets/css/invitation.css';

function AdminInvidation() {
    const { invitationCode } = useParams(),

        navigate = useNavigate();

    const [inviteData, setInviteData] = useState(undefined),

        [isError, setIsError] = useState(false),

        [showForm, setShowForm] = useState(false),

        [showFormIndicator, setShowFormIndicator] = useState(false),
        [errorMsg, setErrorMsg] = useState('');


    const [isPasswordValid, setPasswordValid] = useState(false),

        [errors, setErrors] = useState({}),

        [showPasswordChecklist, setShowPasswordChecklist] = useState(false),

        [showIsDeclining, setShowIsDeclining] = useState(false);


    const [formData, setFormData] = useState({

        first_name: "",

        last_name: "",

        telephone: "",

        password: "",

        passwordAgain: "",

    });


    const {

        first_name,

        last_name,

        telephone,

        password,

        passwordAgain

    } = formData;


    const schema = {

        first_name: joi.string().required().label("First Name"),

        last_name: joi.string().required().label("Last Name"),

        telephone: joi.string().required().label("Phone Number"),

        password: joi.string().required().label("Password"),

        passwordAgain: joi.string().required().label("Confirm Password"),

    }


    const validatePropety = (e) => {

        const { name, value } = e.target;

        const obj = { [name]: value };

        const schemaObj = { [name]: schema[name] };

        const { error } = joi.validate(obj, schemaObj);

        return error ? error.details[0].message : null;

    }


    // Input field change handler

    const handleChange = (e) => {

        const { name, value } = e.target;

        const error = { ...errors };

        const errorMsg = validatePropety(e);

        if (errorMsg) error[name] = errorMsg;

        else delete error[name];


        const getValue = { ...formData };

        getValue[name] = value;


        setFormData(getValue);

        setErrors(error);

    }


    // Validate on Submit

    const validate = () => {

        const options = { abortEarly: false };

        const { error } = joi.validate(formData, schema, options);


        const errors = {};


        if (error) {

            for (let item of error.details) errors[item.path[0]] = item.message;

            setErrors(errors);

        }


        return error ? errors : null;

    }


    const handleCloseForm = () => setShowForm(false);

    const handleOpenForm = () => setShowForm(true);


    const acceptInvite = async (e) => {

        try {

            e.preventDefault();

            setShowFormIndicator(true);


            let isTermsAccepted = document.getElementById('terms').checked;


            const isValid = validate();

            if (isValid) return;


            let payload = { password };


            if (!isTermsAccepted) throw ({ message: 'You must accept our terms and conditions.' });


            if (!inviteData.is_user) payload = { ...payload, first_name, last_name, telephone }


            const { message, data } = await AcceptInvitation(invitationCode, { ...payload });

            navigate('/', { replace: true });

            handleCloseForm();

        } catch ({ message }) {

            toast.error(`${message}`);

        } finally {

            setShowFormIndicator(false);

        }

    }


    const handeFocus = () => {

        setShowPasswordChecklist(true)

    }

    const handeFocusOut = () => {

        setShowPasswordChecklist(false)

    }


    const handleValidateInvitation = async () => {

        try {
            setIsError(false);

            const data = await ValidateInvitation({ invitationCode });

            console.log(data);
            setInviteData(data);

        } catch ({ message }) {

            setIsError(true);
            setErrorMsg(`${message}`);

        }

    }

    const handleDeclineInvitation = async (e) => {

        // try {

        // 	e.preventDefault();

        // 	setShowIsDeclining(true)

        // 	const { message } = await DeclineInvitation({ invitationCode });


        // 	toast.success(`${message}`);

        // 	navigate("/");


        // } catch ({ message }) {

        // 	toast.error(`${message}`);

        // } finally {

        // 	setShowIsDeclining(false)

        // }

    }

    const handleis_userTrue = async (e) => {

        // try {

        // 	e.preventDefault();

        // 	setShowFormIndicator(true);

        // 	const { message, data } = await AcceptInvitation(invitationCode);

        // 	toast.success(`${message}`);

        // 	if (data.stage === null) {

        // 		navigate('/verify', { state: { email: inviteData.data.email } });

        // 	} else {

        // 		navigate('/dashboard', { replace: true });

        // 	}
        // } catch ({ message }) {

        // 	toast.error(`${message}`);

        // } finally {

        // 	setShowFormIndicator(false);

        // }



    };


    useEffect(() => {

        handleValidateInvitation();

    }, []);

    return (
        <div class="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
            <div class="layout-container">
                <article className='inviteContainer '>

                    <div className="logo_container">

                        <Link to="/" className="app-brand-link gap-2">

                            <span className="app-brand-logo demo">

                                <img src={logo_white} alt="logo_white icon" height="100%" width="100%" />

                            </span>

                        </Link>

                    </div>



                    <section className="invite_subContainer">

                        <article className="custom_container_left  d-none d-lg-block">

                            <center className="img_section">

                                <img src={invitation} style={{ width: '55%' }} className="img-fluid" alt="invitation_icon" />

                            </center>

                        </article>

                        <article className=" custom_container_right">

                            {

                                isError ? (

                                    <h3 class=" text-center">{errorMsg}</h3>

                                )

                                    :

                                    inviteData === undefined ?

                                        <RotatingLines width="35" strokeColor="#6495ED" strokeWidth="3" />

                                        :

                                        inviteData.data ?

                                            <div className="d-flex flex-column justify-content-center align-items-center">

                                                <Link to="/" className="app-brand-link gap-2 d-lg-none" style={{ marginBottom: '50px' }}>

                                                    <span className="app-brand-logo demo">

                                                        <img src={logo_white} alt="logo_white icon" height="100%" width="100%" />

                                                    </span>

                                                </Link>

                                                <h1 class="text-center" style={{ color: "#111322" }}>Hello</h1>

                                                <h2 class="text-center" style={{ color: "#111322" }}>{inviteData.data?.email},</h2>

                                                <p className="text-center text-body px-3 mb-0">

                                                    {inviteData?.data?.invited_by} has invited you to join them on Rayda<br />Click any of the buttons below to accept or decline the invitation respectively.

                                                </p>

                                                <div className="row my-3">

                                                    <div className="col-12">

                                                        <button className="btn btn-outline-primary" onClick={handleDeclineInvitation} disabled={showIsDeclining}>{showIsDeclining ? 'Declining...' : 'Decline'}</button>

                                                        <button onClick={inviteData.data.is_user ? handleis_userTrue : handleOpenForm} className="btn btn-primary mx-2" style={{ display: 'inline-block' }} disabled={showIsDeclining}>Accept</button>


                                                    </div>

                                                </div>

                                            </div>

                                            :

                                            <h3 class="text-center">Invalid link!</h3>

                            }


                        </article>


                    </section>

                </article>


                <Modal show={showForm} onHide={handleCloseForm}>

                    <Modal.Header closeButton>

                        <Modal.Title>Accept Invitation</Modal.Title>

                    </Modal.Header>

                    <Form onSubmit={acceptInvite}>

                        <Modal.Body>

                            <div className="row">

                                <InputField

                                    defaultInput={true}

                                    name="first_name"

                                    label="First Name"

                                    inputType="text"

                                    id="first_name"

                                    value={first_name}

                                    placeholder="Enter your first name"

                                    handleChange={handleChange}

                                    error={errors.first_name}

                                />

                                <InputField

                                    defaultInput={true}

                                    name="last_name"

                                    label="Last Name"

                                    inputType="text"

                                    id="last_name"

                                    value={last_name}

                                    placeholder="Enter your last name"

                                    handleChange={handleChange}

                                    error={errors.last_name}

                                />

                                {/* <CustomTelInput

                                    name="telephone"

                                    label="Phone Number"

                                    value={telephone}

                                    setValue={setFormData}

                                    initialData={formData}

                                    error={errors.telephone}

                                /> */}


                                <InputField

                                    defaultInput={true}

                                    name="telephone"

                                    label="Telephone"

                                    inputType="text"

                                    id="telephone"

                                    value={telephone}

                                    placeholder="Enter your phone number"

                                    handleChange={handleChange}

                                    error={errors.telephone}

                                />

                                <InputField

                                    password={true}

                                    name="password"

                                    label="Password"

                                    inputType="password"

                                    id="password"

                                    value={password}

                                    placeholder="Enter your password"

                                    handeFocus={handeFocus}

                                    handeFocusOut={handeFocusOut}

                                    handleChange={handleChange}

                                />

                                <InputField

                                    password={true}

                                    name="passwordAgain"

                                    label="Confirm Password"

                                    inputType="password"

                                    id="passwordAgain"

                                    value={passwordAgain}

                                    placeholder="Enter your current password"

                                    handeFocus={handeFocus}

                                    handeFocusOut={handeFocusOut}

                                    handleChange={handleChange}

                                />

                                {

                                    showPasswordChecklist && (

                                        <div className="mw-4 pl-3">

                                            <PasswordChecklist

                                                rules={["minLength", "specialChar", "number", "capital", "match"]}

                                                minLength={8}

                                                value={password}

                                                valueAgain={passwordAgain}

                                                iconSize={12}

                                                messages={{

                                                    minLength: "Password must minimum of 8 characters long",

                                                    specialChar: "Password must contain a special character",

                                                    number: "Password must contain a number",

                                                    capital: "Password must contain an uppercase letter",

                                                    match: "Password and confirm password values must match",

                                                }}

                                                onChange={

                                                    (isValid) => {

                                                        setPasswordValid(isValid);

                                                    }

                                                }


                                            />

                                        </div>

                                    )

                                }


                                <div class="mb-3">

                                    <div class="form-check">

                                        <input class="form-check-input" type="checkbox" id="terms" name="terms" value='Yes' />

                                        <label class="form-check-label" for="terms">

                                            I agree to

                                            <a href="javascript:void(0);"> privacy policy & terms</a>

                                        </label>

                                    </div>

                                </div>


                            </div>

                        </Modal.Body>

                        <Modal.Footer>

                            {!showFormIndicator && <Button variant="primary" type="submit">Accept Invitation</Button>}

                            {!showFormIndicator && <Button variant="secondary" onClick={handleCloseForm}>Cancel</Button>}

                            {showFormIndicator && <center><RotatingLines width="30" strokeColor="#6495ED" strokeWidth="3" /></center>}

                        </Modal.Footer>

                    </Form>

                </Modal>
            </div>
        </div>
    )
}

export default AdminInvidation