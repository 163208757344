import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import InputField from '../../../components/InputField';
import SelectField from '../../../components/SelectField';
import { CreateSubscriptionRequest, FetchCompanyDetails, FetchCompanyUsers, FetchSubscriptionPlans } from '../../../utils/api/requests';
import joi from 'joi-browser';
import { toast } from 'react-toastify';
import { RotatingLines } from 'react-loader-spinner';

function CreateSubscription() {
    const { companyId } = useParams();
    const [company, setCompany] = useState([]);
    const [subscription, setSubscription] = useState({});
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [users, setUsers] = useState([]);
    const [errors, setErrors] = useState({});
    const [isCreating, setIsCreating] = useState(false);

    const navigate = useNavigate();

    const subscriptionSchema = {
        amount: joi.string().required().label("Amount"),
        currency: joi.string().required().label("Currency"),
        duration: joi.string().required().label("Duration"),
        paid_by: joi.string().required().label("Paid By"),
        payment_date: joi.string().required().label("Payment Date"),
        payment_method: joi.string().required().label("Payment Method"),
        plan: joi.string().required().label("Subscription Plan"),
        start_date: joi.string().required().label("Start Date"),
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        const temp = { ...subscription };
        temp[name] = value;

        setSubscription(temp);
    }

    const validateData = (state, objSchema) => {
        const options = { abortEarly: false };
        const { error } = joi.validate(state, objSchema, options);

        const errors = {};

        if (error) {
            for (let item of error.details) {
                if (item.message !== `"${item.path[0]}" is not allowed`) {
                    errors[item.path[0]] = item.message;
                }

            }

        }

        setErrors(errors);

        return Object.keys(errors).length > 0 ? errors : null;
    }

    const getPageData = async () => {
        try {
            const [{ data }, { data: usersData }, { data: subscriptionData }] = await Promise.all([
                FetchCompanyDetails({ companyId }),
                FetchCompanyUsers({ companyId }),
                FetchSubscriptionPlans()
            ]);

            setCompany(data.company);

            setUsers(usersData.map(user => ({
                key: user.id,
                value: `${user.last_name} ${user.first_name}`
            })));

            setSubscriptionPlans(subscriptionData.map(item => ({
                key: item.id,
                value: item.name
            })));
        } catch (error) {
            // console.log(error)
        }
    }

    const submitForm = async (e) => {
        e.preventDefault();
        try {
            const isNotValid = validateData(subscription, subscriptionSchema);

            if (isNotValid) return;

            setIsCreating(true);

            const data = { 
                company_id: companyId, 
                subscription_plan_id: subscription.plan, 
                amount: subscription.amount, 
                start_date: subscription.start_date, 
                payment_method: subscription.payment_method.toLowerCase(), 
                currency: subscription.currency, 
                paid_by: subscription.paid_by, 
                paid_at: subscription.payment_date, 
                duration: subscription.duration.toLowerCase()
            }

            const {message} = await CreateSubscriptionRequest(data);

            toast.success(message);

            setSubscription({});
            navigate(-1);

        } catch ({error}) {
            console.log(error);
            toast.error(error.message);
        }finally{
            setIsCreating(false);
        }
    }

    useEffect(() => {
        getPageData();
    }, []);

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="breadcrumb-wrapper py-3 mb-4">
                New Subscription
            </h4>
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-4">
                        <div class="card-header d-flex justify-content-between align-items-center">
                            <h5 class="mb-0">Create a new subscription for {company.name}</h5>
                        </div>

                        <div className='card-body'>
                            <form action='' method='post' onSubmit={submitForm}>
                                <div className='row'>
                                    <SelectField
                                        md={true}
                                        name="plan"
                                        label="Subscription Plan"
                                        value={subscription.plan}
                                        onChange={handleChange}
                                        data={subscriptionPlans}
                                        error={errors.plan}
                                    />

                                    <SelectField
                                        md={true}
                                        name="duration"
                                        label="Duration"
                                        value={subscription.duration}
                                        onChange={handleChange}
                                        data={['Monthly', 'Yearly']}
                                        error={errors.duration}
                                    />
                                </div>
                                <div className='row'>
                                    <SelectField
                                        md={true}
                                        name="currency"
                                        label="Currency"
                                        value={subscription.currency}
                                        onChange={handleChange}
                                        data={['NGN', 'USD']}
                                        error={errors.currency}
                                    />

                                    <SelectField
                                        md={true}
                                        name="payment_method"
                                        label="Payment Method"
                                        onChange={handleChange}
                                        value={subscription.payment_method}
                                        data={['Bank deposit', 'Cheque', 'Others']}
                                        error={errors.payment_method}
                                    />
                                </div>
                                <div className='row'>
                                    <InputField
                                        defaultInput={true}
                                        name="amount"
                                        label="Amount"
                                        inputType="number"
                                        id="amount"
                                        value={subscription.amount}
                                        placeholder="Amount"
                                        handleChange={handleChange}
                                        md={true}
                                        error={errors.amount}
                                    />
                                    <SelectField
                                        md={true}
                                        name="paid_by"
                                        label="Paid By"
                                        value={subscription.paid_by}
                                        onChange={handleChange}
                                        data={users}
                                        error={errors.paid_by}
                                    />
                                </div>
                                <div className='row'>
                                    <InputField
                                        defaultInput={true}
                                        name="payment_date"
                                        label="Payment Date"
                                        inputType="date"
                                        id="payment_date"
                                        value={subscription.payment_date}
                                        placeholder="Payment Date"
                                        handleChange={handleChange}
                                        md={true}
                                        error={errors.payment_date}
                                    />
                                    <InputField
                                        defaultInput={true}
                                        name="start_date"
                                        label="Start Date"
                                        inputType="date"
                                        id="start_date"
                                        value={subscription.start_date}
                                        placeholder="Start Date"
                                        handleChange={handleChange}
                                        md={true}
                                        error={errors.start_date}
                                    />
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        {isCreating && <RotatingLines width="35" strokeColor="#6495ED" strokeWidth="3" />}
                                        {!isCreating && <button className='btn btn-primary'>Create subscription</button>}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default CreateSubscription