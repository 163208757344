import api from "../axios";

/**
 * Login user if the credentails are valid
 * @param {{ name: string, email: string }} credentials data to login a new user
 * @returns {Promise} that will resolve to a new user object or an error object if the supplied credentials are not valid
 */
export const LoginUser = async ({ email, password }) => {
  try {
    const { data } = await api.post(`/login`, { email, password });

    return data;
  } catch (error) {
   throw error.response.data;
  }
}

/**
 * Registerd a new user if the credentails are valid
 * @param {{ name: string, email: string }} credentials data to register a new user
 * @returns {Promise} that will resolve to a new user object or an error object if the supplied credentials are not valid
 */
export const RegisterUser = async ({ email, password, last_name, first_name, telephone }) => {
  try {
    const data = await api.post(`/users`, { email, password, last_name, first_name, telephone });

    return data;
  } catch (error) {
   throw error.response.data;
  }
}

/**
 * Verify a new user
 * @param {{ otp: Number }} otp one time pad sent to the users email
 * @returns {Promise} that will resolve to a new user object or an error object if the supplied credentials are not valid
 */

export const VerifyUser = async ({ otp }) => {
  try {
    const data = await api.put(`/users/verify`, { otp });

    return data;
  } catch (error) {
   throw error.response.data;
  }
}

/**
 * Resend Verification code to a new user
 * @returns {Promise} that will resolve to a new user object or an error object if the supplied credentials are not valid
 */

export const ResendUserVerificationCode = async () => {
  try {
    const {data} = await api.get(`/users/resend-code`);

    return data;
  } catch (error) {
   throw error.response.data;
  }
}

/**
 * Send Users detail
 * @param {{ first_name: String, last_name: String, telephone: Number }} details user's detail
 * @returns {Promise} that will resolve to a new user object or an error object if the supplied credentials are not valid
 */

export const SendUsersDetail = async ({ first_name, last_name, telephone }) => {
  try {
    const { data: { data } } = await api.put(`/users/details`, { first_name, last_name, telephone });

    return data;
  } catch (error) {
   throw error.response.data;
  }
}
