import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter"

const SelectField = ({
    required,
    name,
    label,
    data,
    onChange,
    value,
    error,
    isNexted,
    asideValue,
    toggle,
    toggleTarget,
    placeholder = undefined,
    initialValue,
    children,
    handleBlur,
    md
}) => {

    return (
        <div className={md ? "col-12 col-lg-6 mb-3" : "col-md-12 mb-3"}>
            <label htmlFor={name} className="form-label">{label} {required && <span><sup class="text-danger" style={{ fontSize: '0.7rem' }}>*</sup></span>}</label>
            {
                asideValue && (
                    <a
                        style={{ float: 'right' }}
                        href="#"
                        data-bs-toggle={toggle}
                        data-bs-target={toggleTarget}
                    ><i class='bx bx-plus-circle' style={{ fontSize: 15 }}></i> <span style={{ fontSize: 13 }}>{asideValue}</span></a>
                )
            }

            {
                (data && data[0]?.key) ? (
                    <select defaultValue={'Select'} className="form-select" name={name} value={value} id={name} onChange={onChange} onBlur={handleBlur}>
                        <option value="" selected disabled>{placeholder ? `${placeholder}` : 'Please select'}</option>
                        {
                            data?.map((item, index) => (
                                <option key={index} value={item.key}>{capitalizeFirstLetter(item.value)}</option>
                                )
                            )
                        }

                    </select>
                ) : (
                    <select defaultValue={'Select'} className="form-select" name={name} value={value} id={name} onChange={onChange} onBlur={handleBlur}>
                        <option value="" selected>{placeholder ? `${placeholder}` : 'Please select'}</option>
                        {
                            data?.map((item, index) => (
                                <option key={index} value={item}>{capitalizeFirstLetter(item)}</option>
                                )
                            )
                        }

                    </select>
                )
            }


            {
                error && <div className="alert alert-danger">{error}</div>
            }

            {children}
        </div>
    )
}

export default SelectField