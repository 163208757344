import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import joi from "joi-browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from 'react-redux';

import '../../assets/css/page/login.css'
import ImageFlexContainer from '../../components/ImageFlexContainer'
import reg_Aside from '../../assets/img/components/reg_Aside.png'
import logo from "../../assets/img/app_logo.png"
import InputField from '../../components/InputField';
import { login } from '../../store/user';
import { LoginUser } from '../../utils/api/authentication';

const Login = () => {
    const navigate = useNavigate();
	const dispatch = useDispatch();

	const [formData, setFormData] = useState({
		email: "",
		password: "",
	})

    const [errors, setErrors] = useState({});
    const [showSpinner, setShowSpinner] = useState(false);

    const schema = {
		email: joi.string().email().required().label("Email"),
		password: joi.string().required().label("Password")
	}

    const {email, password} = formData;

    // Input field validation handler
	const validatePropety = (e) => {
		const { name, value } = e.target;
		const obj = { [name]: value };
		const schemaObj = { [name]: schema[name] };
		const { error } = joi.validate(obj, schemaObj);
		return error ? error.details[0].message : null;
	}

    // Input field change handler
	const handleChange = (e) => {
		const { name, value } = e.target;
		const error = { ...errors };
		const errorMsg = validatePropety(e);
		if (errorMsg) error[name] = errorMsg;
		else delete error[name];

		const getValue = { ...formData };
		getValue[name] = value;

		setFormData(getValue);
		setErrors(error);
	}

    // Validate on Submit
	const validate = () => {
		const options = { abortEarly: false };
		const { error } = joi.validate(formData, schema, options);

		const errors = {};

		if (error) {
			for (let item of error.details) errors[item.path[0]] = item.message;
			setErrors(errors);
		}

		return error ? errors : null;
	}


    const performLogin = async (e) => {
		try {
			e.preventDefault();
			setShowSpinner(true);
			//get values from user
			let email = document.getElementById('email').value;
			let password = document.getElementById('password').value;

			//validation stuff
			let isError = false;
			if (email === '') {
				toast.error('email is required!')
				isError = true;
			}

			if (password === '') {
				toast.error('password is required!');
				isError = true;
			}

			if (isError) return false;

			const {data: {token}} = await LoginUser({ email, password });
			dispatch(login({ email }));

			localStorage.setItem('rayda-admin-token', token);

			// MixPanel.identify(`${email}`);
			// MixPanel.track('Successful login');

			//redirect to the dashboard
			navigate('/dashboard', { replace: true });

		} catch ({ message }) {
			// MixPanel.track('Unsuccessful login');
			toast.error(`${message}`);
		} finally {
			setShowSpinner(false);
		}
	}



  return (
    <ImageFlexContainer img={reg_Aside} verify={true} login={true}>
		<div className="login_container">
			<div className="card-body">
				<div className="app-brand justify-content-center">
					<a href="#" className="app-brand-link gap-2">
						<img src={logo} className="brand_logo"/>
					</a>
				</div>

				<center>
					<h4 className="mb-2">Welcome back!</h4>
					<p className="mb-4">Sign into your account</p>
				</center>

				<form className="mb-3">
                    <InputField
                        defaultInput={true}
                        name="email"
                        label="Email"
                        inputType="email"
                        id="email"
                        value={email}
                        placeholder="Enter your email"
                        handleChange={handleChange}
                        error={errors.email}
                    />
					<div className="form-password-toggle mb-3">
						{/* <div className="d-flex justify-content-between">
							<label className="form-label" htmlFor="password">Password</label>
							<Link to="/forgot-password">
								<small>Forgot Password?</small>
							</Link>
						</div> */}
                        <InputField
							password={true}
                            hasForgot_password={true}
							name="password"
							label="Password"
							inputType="password"
							id="password"
							value={password}
							placeholder="Enter your password"
							handleChange={handleChange}
						/>
						{/* <div className="input-group input-group-merge">
							<input
								type={showPassword === true ? 'text':'password'}
								id="password"
								className="form-control"
								name="password"
								placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
								aria-describedby="password"
							/>
							{!showPassword && (<span className="input-group-text cursor-pointer" onClick={() => setShowPassword(!showPassword)}><i className="bx bx-hide"></i></span>)}
							{ showPassword && (<span className="input-group-text cursor-pointer" onClick={() => setShowPassword(!showPassword)}><i className="bx bx-show"></i></span>)}
						</div> */}
					</div>
					<div className="mb-3">
						<div className="form-check">
							<input className="form-check-input" type="checkbox" id="remember-me" />
							<label className="form-check-label" htmlFor="remember-me"> Remember Me</label>
						</div>
					</div>
					<div className="mb-3">

						{!showSpinner && <button className="btn btn-primary d-grid w-100" type="submit" onClick={performLogin}>Sign in</button>}
						{showSpinner && <center><Oval width="35" strokeColor="#6495ED" strokeWidth="3" /></center>}
					</div>
				</form>

			</div>
		</div>
	</ImageFlexContainer>
  )
}

export default Login