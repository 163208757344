import { Fragment, useState } from "react"
import { Typeahead } from "react-bootstrap-typeahead"
import { Link } from "react-router-dom";

import '../assets/css/component/InputField.css'

const InputField = (props) => {
    const {
        name, 
        ref, 
        label, 
        inputType, 
        value, 
        placeholder, 
        defaultInput, 
        handleChange, 
        error, 
        typeAheadData, 
        hasTypeAHead, 
        handleTypeAheadChange, 
        asideValue, 
        toggle, 
        toggleTarget, 
        hasTag, 
        htmlFor, 
        id, 
        password, 
        handeFocus, 
        handeFocusOut,
        hasForgot_password,
        md
    } = props
    const [showPassword, setShowPassword] = useState(false);

  return (
    <Fragment>
        {
            defaultInput && (
                <div className={md ? 'col-md-6 mb-3' : 'col-md-12 mb-3'}>
                    <label htmlFor={name} className="form-label">{label}</label>
                    {
                        asideValue && (
                            <a
                                style={{ float: 'right' }}
                                href="#"
                                data-bs-toggle={toggle}
                                data-bs-target={toggleTarget}
                            ><i class='bx bx-plus-circle' style={{ fontSize: 16 }}></i> {asideValue}</a>
                        )
                    }

                    <input 
                        className="form-control" 
                        type={inputType}
                        id={asideValue ? id : name} 
                        name={name}
                        value={value} 
                        placeholder={placeholder}
                        onChange={handleChange} 
                    />
                    
                    {
                        error && <div className="alert alert-danger">{error}</div>
                    }
                </div>
            )
        }
        {
            hasTypeAHead && (
                <div className={md ? 'col-md-6 mb-3' : 'col-md-12 mb-3'}>
                    <label htmlFor={name} className="form-label">{label}</label>
                    <input ref={ref} name={name} id={name} onChange={handleChange}  type="hidden" />
                    <Typeahead 
                        id={name}
                        clearButton
                        labelKey={name}
                        onChange={(text) => handleTypeAheadChange(text[0])}
                        options={typeAheadData}
                        placeholder={placeholder}
                        value={value}
                    />

                    {
                        error && <div className="alert alert-danger">{error}</div>
                    }
                </div>
            )
        }

        {
            hasTag && (
                <div class="col mb-3">
                    <label htmlFor={htmlFor} className="form-label">{label}</label>
                    <input
                        id={id}
                        className="form-control"
                        name={name}
                        value={value}
                        onChange={handleChange}
                        placeholder={placeholder} />

                    {
                        error && <div className="alert alert-danger">{error}</div>
                    }
                </div>
            )
        }

        {
            password && (
                <div className="form-password-toggle mb-3">
                    {/* d-flex justify-content-between align-items-center */}
                    <div className="custom_flex">
                        <label htmlFor={name} className="form-label">{label}</label>
                        {
                            hasForgot_password && (
                                <Link to="/forgot-password">
                                    <small>Forgot Password?</small>
                                </Link>
                            )
                        }
                        
                    </div>
                    
                    <div className="input-group input-group-merge">
                        <input
                            type={showPassword === true ? 'text':'password'}
                            onChange={handleChange}
                            id={name}
                            className="form-control"
                            name={name}
                            value={value}
                            placeholder={placeholder}
                            onFocus={handeFocus}
                            onBlur={handeFocusOut}
                        />
                        {!showPassword && (<span className="input-group-text cursor-pointer" onClick={() => setShowPassword(!showPassword)}><i className="bx bx-hide"></i></span>)}
                        {showPassword && (<span className="input-group-text cursor-pointer" onClick={() => setShowPassword(!showPassword)}><i className="bx bx-show"></i></span>)}
                    </div>
                </div> 
            )
        }
    </Fragment>
    
  )
}

export default InputField