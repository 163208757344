import React, { useEffect, useState } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { Link, useNavigate } from 'react-router-dom';
import { FetchCompanies } from '../../../utils/api/requests';

function SelectCompany() {
    const [companies, setCompanies] = useState([]),
        [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const fetchAllCompanies = async () => {
        try {
            const { data } = await FetchCompanies();
            console.log(data);
            setCompanies(data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    window.selectCompany = (companyId) => {
        navigate(companyId);
    }

    const tableContent = () => {
        const dt_invoice_table = window.$('.invoice-list-table');
        if (dt_invoice_table.length) {
            const dt_invoice = dt_invoice_table.DataTable({
                data: companies,
                "bDestroy": true,

                columns: [
                    { data: '' },
                    { data: 'name' },
                    { data: 'status' },
                    {},
                ],
                columnDefs: [
                    {
                        // For Responsive
                        className: 'control',
                        responsivePriority: 2,
                        searchable: false,
                        orderable: false,
                        targets: 0,
                        render: function (data, type, full, meta) {
                            return '';
                        }
                    },
                    {
                        targets: 1,
                        render: function (data, type, full, meta) {
                            return `<div class="d-flex justify-content-start align-items-center">
								<div class="d-flex flex-column">
								<span>${full.name}</span>
								</div>
								</div>`;
                        }
                    },
                    {
                        targets: 2,
                        render: function (data, type, full, meta) {
                            return `<div class="d-flex justify-content-start align-items-center">
								<div class="d-flex flex-column">
								<span class="badge rounded-pill bg-label-success">${full.status}</span>
								</div>
								</div>`;
                        }
                    },
                    {
                        // Actions
                        targets: -1,
                        title: 'Actions',
                        searchable: false,
                        orderable: false,
                        render: function (data, type, full, meta) {
                            return (
                                `<div class="d-flex align-items-center">
								<a href="javascript:void(0);" class="btn btn-sm btn-outline-primary" data-bs-placement="top" onclick="selectCompany('${full.id}')">Select</a>
								 </div>`
                            );
                        }
                    }
                ],
                order: [[1, 'asc']],
                dom:
                    '<"row ms-2 me-3"' +
                    '<"col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start gap-2"l<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start mt-md-0 mt-3"B>>' +
                    '<"col-12 col-md-6 d-flex align-items-center justify-content-end flex-column flex-md-row pe-3 gap-md-2"f<"invoice_status mb-3 mb-md-0">>' +
                    '>t' +
                    '<"row mx-2"' +
                    '<"col-sm-12 col-md-6"i>' +
                    '<"col-sm-12 col-md-6"p>' +
                    '>',
                language: {
                    sLengthMenu: '_MENU_',
                    search: '',
                    searchPlaceholder: 'Search companies'
                },
                responsive: {
                    details: {
                        display: window.$.fn.dataTable.Responsive.display.modal({
                            header: function (row) {
                                var data = row.data();
                                return 'Details of company ' + data['name'];
                            }
                        }),
                        type: 'column',
                        renderer: function (api, rowIdx, columns) {
                            var data = window.$.map(columns, function (col, i) {
                                return col.title !== '' // ? Do not show row in modal popup if title is blank (for check box)
                                    ? '<tr data-dt-row="' +
                                    col.rowIndex +
                                    '" data-dt-column="' +
                                    col.columnIndex +
                                    '">' +
                                    '<td>' +
                                    col.title +
                                    ':' +
                                    '</td> ' +
                                    '<td>' +
                                    col.data +
                                    '</td>' +
                                    '</tr>'
                                    : '';
                            }).join('');

                            return data ? window.$('<table class="table"/><tbody />').append(data) : false;
                        }
                    }
                },

            });
        }

        // On each datatable draw, initialize tooltip
        dt_invoice_table.on('draw.dt', function () {
            var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
            var tooltipList = tooltipTriggerList?.map(function (tooltipTriggerEl) {
                return new window.bootstrap.Tooltip(tooltipTriggerEl, {
                    boundary: document.body
                });
            });
        });

        setTimeout(() => {
            window.$('.dataTables_filter .form-control').removeClass('form-control-sm');
            window.$('.dataTables_length .form-select').removeClass('form-select-sm');
        }, 300);
    }

    useEffect(() => {
        fetchAllCompanies();

    }, []);

    useEffect(() => {
        tableContent();
    }, [companies]);

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="breadcrumb-wrapper py-3 mb-4">
                New Subscription
            </h4>
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-4">
                        <div class="card-header d-flex justify-content-between align-items-center">
                            <div className='container-fluid' style={{padding: '0px'}}>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <h5 class="mb-0">Select company to continue</h5>
                                    </div>
                                    <div className='col-md-6'>
                                        <button className='btn btn-primary btn-sm float-right' style={{float: 'right'}} onClick={() => {navigate(-1)}}>Back</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {loading ?
                            (<center><RotatingLines width="35" strokeColor="#6495ED" strokeWidth="3" /></center>)
                            :
                            (
                                <div class="card-datatable table-responsive">
                                    <table className="invoice-list-table table border-top" id="viewAssets_table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>COMPANY</th>
                                                <th>STATUS</th>
                                                <th>ACTIONS</th>
                                            </tr>
                                        </thead>
                                    </table>

                                </div>
                            )
                        }

                    </div>
                </div>

            </div>
        </div>
    )
}

export default SelectCompany