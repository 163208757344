const Auth = ({children}) => {
    return (
        <div class="container-xxl">
            <div class="authentication-wrapper authentication-basic container-p-y">
                <div class="authentication-inner py-4">

                    {children}

                </div>
            </div>
        </div>
    );
}
 
export default Auth;