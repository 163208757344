import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";

import "../../assets/css/page/login.css";
import { login } from "../../store/user";
import { LoginUser } from "../../utils/api/authentication";

const AutoLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    authLogin();
  });

  const authLogin = async () => {
    const email = "hello@rayda.co";
    const password = "*Rayda123#";

    const {
      data: { token },
    } = await LoginUser({ email, password });
    dispatch(login({ email }));
    localStorage.setItem("rayda-admin-token", token);

    //redirect to the dashboard
    navigate("/dashboard", { replace: true });
  };

  return <></>;
};

export default AutoLogin;
