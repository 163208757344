import React from 'react'
import { Row } from 'react-bootstrap'

function CompanyInsurance({ insuranceData }) {
  return (
    <div class="card mb-4">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5 class="mb-0">Insurance</h5>
      </div>

      <div class="card-datatable table-responsive">
        <table className="view_insurance_table table border-top" id="view_insurance_table">
          <thead>
            <tr>
              <th></th>
              <th>PROVIDER</th>
              <th>PREMIUM</th>
              <th>ISSUE DATE</th>
              <th>STATUS</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
        </table>

      </div>

    </div>
  )
}

export default CompanyInsurance