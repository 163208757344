import React, { useEffect } from 'react'
import { Row } from 'react-bootstrap'
import { capitalizeFirstLetter } from '../utils/capitalizeFirstLetter';
import { config } from './../utils/config';
import ChartIndicator from './ChartIndicator';

function CompanyDetails({ company, analytics }) {
  let cardColor, headingColor, axisColor, borderColor, shadeColor;

  cardColor = config.colors.white;
  headingColor = config.colors.headingColor;
  axisColor = config.colors.axisColor;
  borderColor = config.colors.borderColor;
  shadeColor = 'light';

  const showInsuranceChart = () => {
    document.getElementById('impressionDonutChart').innerHTML = "";

    const impressionDonutChartEl = document.querySelector('#impressionDonutChart'),
      impressionDonutChartConfig = {
        chart: {
          height: 134,
          fontFamily: 'IBM Plex Sans',
          type: 'donut'
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          padding: {
            bottom: -10
          }
        },
        series: [analytics.insured_assets, analytics.uninsured_assets],
        labels: [`Insured (${analytics.insured_assets})`, `Uninsured (${analytics.uninsured_assets})`],
        stroke: {
          width: 0,
          lineCap: 'round'
        },
        colors: ['#556275', '#F2DA00'],
        plotOptions: {
          pie: {
            donut: {
              size: '90%',
              labels: {
                show: true,
                name: {
                  fontSize: '0.938rem',
                  offsetY: 20
                },
                value: {
                  show: true,
                  fontSize: '1.625rem',
                  fontFamily: 'Rubik',
                  fontWeight: '500',
                  color: headingColor,
                  offsetY: -20,
                  formatter: function (val) {
                    return val;
                  }
                },
                total: {
                  show: false,
                  label: 'Insurance',
                  color: config.colors.secondary,
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce(function (a, b) {
                      return a + b;
                    }, 0);
                  }
                }
              }
            }
          }
        },
        legend: {
          show: false,
          position: 'right',
          horizontalAlign: 'center',
          labels: {
            colors: axisColor,
            useSeriesColors: false
          },
          markers: {
            width: 10,
            height: 10,
            offsetX: -3
          }
        }
      };

    if (typeof impressionDonutChartEl !== undefined && impressionDonutChartEl !== null) {
      const impressionDonutChart = new window.ApexCharts(impressionDonutChartEl, impressionDonutChartConfig);
      impressionDonutChart.render();
    }
  }

  const showAssetsChart = () => {
    document.getElementById('multi-radial-chart').innerHTML = "";

    var multiRadialOptions = {
      chart: {
        height: 300,
        type: "radialBar",
      },
      colors: [config.colors.primary, config.colors.danger, config.colors.warning, config.colors.purple, config.colors.secondary],
      series: [
        ((analytics.assets.active_insurance / company.assets) * 100).toFixed(),
        ((analytics.assets.expired_insurance / company.assets) * 100).toFixed(),
        ((analytics.assets.expired_insurance / company.assets) * 100).toFixed(),
        ((analytics.assets.approved_claims / company.assets) * 100).toFixed(),
        ((analytics.assets.denied_claims / company.assets) * 100).toFixed(),
      ],
      plotOptions: {
        radialBar: {
          offsetY: -10,
          hollow: {
            size: "40%"
          },
          track: {
            margin: 10,
            background: '#fff',
          },
          dataLabels: {
            name: {
              fontSize: '15px',
              colors: [config.colors.secondary],
              fontFamily: "IBM Plex Sans",
              offsetY: 25,
            },
            value: {
              fontSize: '30px',
              fontFamily: "Rubik",
              offsetY: -15,
            },
            total: {
              show: false,
              label: 'Total Visits',
              fontSize: '15px',
              fontWeight: 400,
              fontFamily: "IBM Plex Sans",
              color: config.colors.secondary
            }
          }
        }
      },
      stroke: {
        lineCap: "round",
      },
      labels: ['Active Insurances', 'Expired Insurances', 'External Insurances', 'Approved Claims', 'Denied Claims']
    };

    var multiradialChart = new window.ApexCharts(
      document.querySelector("#multi-radial-chart"),
      multiRadialOptions
    );
    multiradialChart.render();
  }

  const showDocumentsChart = () => {
    document.getElementById('donut-chart').innerHTML = "";

    let labels = analytics.documents.details.map((document => {
      return `${document.type.title} (${document.total})`
    }));

    let series = analytics.documents.details.map((document => {
      return document.total
    }));

    const impressionDonutChartEl = document.querySelector('#donut-chart'),
      impressionDonutChartConfig = {
        chart: {
          height: 200,
          fontFamily: 'IBM Plex Sans',
          type: 'donut'
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          padding: {
            bottom: -10
          }
        },
        series: series,
        labels: labels,
        stroke: {
          width: 0,
          lineCap: 'round'
        },
        colors: [config.colors.primary, config.colors.warning, config.colors.danger, config.colors.success, config.colors.info],
        plotOptions: {
          pie: {
            donut: {
              size: '90%',
              labels: {
                show: false,
                name: {
                  fontSize: '0.938rem',
                  offsetY: 20
                },
                value: {
                  show: true,
                  fontSize: '1.625rem',
                  fontFamily: 'Rubik',
                  fontWeight: '500',
                  color: headingColor,
                  offsetY: -20,
                  formatter: function (val) {
                    return val;
                  }
                },
                total: {
                  show: false,
                  label: 'Insurance',
                  color: config.colors.secondary,
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce(function (a, b) {
                      return a + b;
                    }, 0);
                  }
                }
              }
            }
          }
        },
        legend: {
          show: false,
          position: 'right',
          horizontalAlign: 'center',
          labels: {
            colors: axisColor,
            useSeriesColors: false
          },
          markers: {
            width: 10,
            height: 10,
            offsetX: -3
          }
        }
      };

    if (typeof impressionDonutChartEl !== undefined && impressionDonutChartEl !== null) {
      const impressionDonutChart = new window.ApexCharts(impressionDonutChartEl, impressionDonutChartConfig);
      impressionDonutChart.render();
    }

  }

  const showInsuranceBoxChart = () => {
    document.getElementById('insurance-donut-chart').innerHTML = "";

    let labels = analytics.insurance.details.map((insurance => {
      return `${insurance.assetType.name} (${insurance.total})`
    }));

    let series = analytics.insurance.details.map((insurance => {
      return insurance.total
    }));

    const impressionDonutChartEl = document.querySelector('#insurance-donut-chart'),
      impressionDonutChartConfig = {
        chart: {
          height: 200,
          fontFamily: 'IBM Plex Sans',
          type: 'donut'
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          padding: {
            bottom: -10
          }
        },
        series: series,
        labels: labels,
        stroke: {
          width: 0,
          lineCap: 'round'
        },
        colors: [config.colors.primary, config.colors.warning, config.colors.danger, config.colors.success, config.colors.info],
        plotOptions: {
          pie: {
            donut: {
              size: '90%',
              labels: {
                show: false,
                name: {
                  fontSize: '0.938rem',
                  offsetY: 20
                },
                value: {
                  show: true,
                  fontSize: '1.625rem',
                  fontFamily: 'Rubik',
                  fontWeight: '500',
                  color: headingColor,
                  offsetY: -20,
                  formatter: function (val) {
                    return val;
                  }
                },
                total: {
                  show: false,
                  label: 'Insurance',
                  color: config.colors.secondary,
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce(function (a, b) {
                      return a + b;
                    }, 0);
                  }
                }
              }
            }
          }
        },
        legend: {
          show: false,
          position: 'right',
          horizontalAlign: 'center',
          labels: {
            colors: axisColor,
            useSeriesColors: false
          },
          markers: {
            width: 10,
            height: 10,
            offsetX: -3
          }
        }
      };

    if (typeof impressionDonutChartEl !== undefined && impressionDonutChartEl !== null) {
      const impressionDonutChart = new window.ApexCharts(impressionDonutChartEl, impressionDonutChartConfig);
      impressionDonutChart.render();
    }

  }

  useEffect(() => {
    showInsuranceChart();

    showAssetsChart();

    showDocumentsChart();

    showInsuranceBoxChart();
  }, []);

  return (
    <>
      <div className='row'>
        <div className='col-md-4'>
          <div class="card">
            <div class="card-body">
              <div className='row'>
                <div className='col-md-5'>
                  <div id="impressionDonutChart"></div>
                </div>
                <div className='col-md-7'>
                  <h5>Insurance</h5>
                  <ChartIndicator color="#556275" /><label>Insured ({analytics.insured_assets})</label><br />
                  <ChartIndicator color="#F2DA00" /><label>Uninsured ({analytics.uninsured_assets})</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-2'>
          <div class="card h-100">
            <div class="card-body text-center">
              <div class="avatar mx-auto mb-2">
                <span class="avatar-initial bg-label-secondary rounded-circle"
                ><i class="bx bx-user fs-4"></i
                ></span>
              </div>
              <h2 class="mb-0">{company.users}</h2>
              <span class="d-block text-nowrap">Total  Active Users</span>
            </div>
          </div>
        </div>
        <div className='col-md-2'>
          <div class="card h-100">
            <div class="card-body text-center">
              <div class="avatar mx-auto mb-2">
                <span class="avatar-initial bg-label-success rounded-circle"
                ><i class="bx bx-group fs-4"></i
                ></span>
              </div>
              <h2 class="mb-0">{analytics.personnels}</h2>
              <span class="d-block text-nowrap">Total Personnel</span>
            </div>
          </div>
        </div>
        <div className='col-md-2'>
          <div class="card h-100">
            <div class="card-body text-center">
              <div class="avatar mx-auto mb-2">
                <span class="avatar-initial bg-label-warning rounded-circle"
                ><i class="bx bx-briefcase-alt fs-4"></i
                ></span>
              </div>
              <h2 class="mb-0">{company.offices}</h2>
              <span class="d-block text-nowrap">Office locations</span>
            </div>
          </div>
        </div>
        <div className='col-md-2'>
          <div class="card h-100">
            <div class="card-body text-center">
              <div class="avatar mx-auto mb-2">
                <span class="avatar-initial bg-label-info rounded-circle"
                ><i class="bx bx-cart fs-4"></i
                ></span>
              </div>
              <h2 class="mb-0">{analytics.vendors}</h2>
              <span class="d-block text-nowrap">total vendors</span>
            </div>
          </div>
        </div>
      </div>

      <div className='row' style={{ marginTop: '40px' }}>
        <div class="col-md-4 h-100">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">
                Documents
                <p style={{ fontSize: '38px' }}>{analytics.documents.total}</p>
              </h5>
            </div>
            <div class="card-body">
              <div id="donut-chart" class="d-flex justify-content-center"></div>
              <table className='table table-borderless'>
                <tbody>
                  {
                    analytics.documents.details.map((document) => {
                      return (
                        <tr>
                          <td>{capitalizeFirstLetter(document.type.title)}</td>
                          <td>{document.total}</td>
                          <td>{((document.total / analytics.documents.total) * 100).toFixed()}%</td>
                        </tr>
                      )
                    })
                  }

                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-md-4 h-100">
          <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h5 class="card-title">
                Assets
                <p style={{ fontSize: '38px' }}>{company.assets}</p>
              </h5>
            </div>
            <div class="card-body">
              <div id="multi-radial-chart"></div>
              <table className='table table-borderless'>
                <tbody>
                  <tr>
                    <td>Active Insurances</td>
                    <td>{analytics.assets.active_insurance}</td>
                    <td>{((analytics.assets.active_insurance / company.assets) * 100).toFixed()}%</td>
                  </tr>
                  <tr>
                    <td>Expired Insurances</td>
                    <td>{analytics.assets.expired_insurance}</td>
                    <td>{((analytics.assets.expired_insurance / company.assets) * 100).toFixed()}%</td>
                  </tr>
                  <tr>
                    <td>External Insurances</td>
                    <td>{analytics.assets.external_insurance}</td>
                    <td>{((analytics.assets.external_insurance / company.assets) * 100).toFixed()}%</td>
                  </tr>
                  <tr>
                    <td>Approved Claims</td>
                    <td>{analytics.assets.approved_claims}</td>
                    <td>{((analytics.assets.approved_claims / company.assets) * 100).toFixed()}%</td>
                  </tr>
                  <tr>
                    <td>Denied Claims</td>
                    <td>{analytics.assets.denied_claims}</td>
                    <td>{((analytics.assets.denied_claims / company.assets) * 100).toFixed()}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-md-4 h-100">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">
                Insurances
                <p style={{ fontSize: '38px' }}>{analytics.insurance.total}</p>
              </h5>
            </div>
            <div class="card-body">
              <div id="insurance-donut-chart" class="d-flex justify-content-center"></div>
              <table className='table table-borderless'>
                <tbody>
                  {
                    analytics.insurance.details.map((insurance) => {
                      return (
                        <tr>
                          <td>{insurance.assetType.name}</td>
                          <td>{insurance.total}</td>
                          <td>{((insurance.total / analytics.insurance.total) * 100).toFixed()}%</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default CompanyDetails