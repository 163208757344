import '../assets/css/component/imageFlexContainer.css'

const ImageFlexContainer = ({img, verify,login, children}) => {
  return (
    <div className='imageFlexContainer'>
        {/* col-lg-7 */}
        <div className="custom_container_aside_left  d-none d-lg-block">
            <center>
                <img src={img} alt={img} style={{ width: '65%' }} className="img-fluid" />  
            </center>
            
            {
                login ? (
                    <center>
                        <h4 className="mb-1 mt-4 h3">Did you know?  🤓</h4>
                        <p className="mt-3 custom_paragram">Tags are a great way to filter and categorise your assets. 
                            Add tags to see how your assets are categorised based on your operations.
                        </p>
                    </center>
                ):(
                    <center>
                        <h4 className="mb-1 mt-4 h3">A few clicks to get started 🚀</h4>
                        <p className="mt-3">Let’s get started with your account and you can <br/> start managing your assets right away.</p>
                    </center>
                )
            }
            
        </div>
        {/* col-sm-12 col-md-12 col-lg-5 */}
        <div className={verify ? " custom_container_aside_right" : "col-sm-12 col-md-9 col-lg-5 custom_container" }>
            {children}
        </div>
        {/* </div> */}
    </div>
  )
}

export default ImageFlexContainer