import React from 'react'
import { Col, Row } from 'react-bootstrap'
import dateHelper from 'date-helper-util';

function CompanyUsers({ users }) {
    return (
        <Row>
            <Col>
                <div className='card-body'>
                    <div class="card-datatable table-responsive">
                        <table class="dt-fixedheader table table-bordered" id="view_users_table">
                            <thead>
                                <tr>
                                    <th>USER</th>
                                    <th>ROLE</th>
                                    <th>ACCOUNT CREATED</th>
                                    <th>LAST LOGIN DATE</th>
                                    <th>STATUS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    users.map(user => <tr key={user.id}>
                                        <td>{user.last_name + ' ' + user.first_name}</td>
                                        <td></td>
                                        <td>{dateHelper(new Date(user.created_at)).getFormated("DD/MM/YYYY")}</td>
                                        <td>{user.last_login}</td>
                                        <td><span class="badge rounded-pill bg-label-success">Active</span></td>
                                    </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default CompanyUsers