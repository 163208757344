import logo from "./../../assets/img/app_logo.png"
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { URL } from "../../utils/AppParams";
import ImageFlexContainer from "../../components/ImageFlexContainer";
import reg_Aside from './../../assets/img/components/reg_Aside.png';
import "./../../assets/css/forget_password.css";
import { ForgotPasswordRequest } from "../../utils/api/requests";

const ForgotPassword = () => {
    const [showSpinner, setShowSpinner] = useState(false);
    const navigate = useNavigate();

    let sendResetCode = async (e) => {
        try {
            e.preventDefault();

            let email = document.getElementById('email').value;

            if (email === '') {
                toast.error('Email is required!');
                return false;
            }

            setShowSpinner(true);

            const { message } = await ForgotPasswordRequest({ "email": email })

            navigate('/verify-reset-code', { state: { email: email }, replace: true });

        } catch (error) {
            toast.error(error.message);
        }finally{
            setShowSpinner(false);
        }
    }

    return (
        <ImageFlexContainer img={reg_Aside} verify={true} login={true}>
            <div class="forget_password_container">
                <div class="card-body">

                    <div class="forget_p_app-brand">
                        <a href="index.html" class="forget_p_app-brand-link gap-2">
                            <img src={logo} className="forget_p_brand_logo" />
                        </a>
                    </div>

                    <center><h4 class="mb-2">Forgot Password? 🔒</h4>
                        <p class="mb-4 custom_p">Enter your email and we'll send you a code to reset your password</p>
                    </center>
                    <form id="formAuthentication" class="mb-3" action="#" method="POST" onSubmit={sendResetCode}>
                        <div class="mb-3">
                            <label htmlFor="email" class="form-label">Email</label>
                            <input
                                type="text"
                                class="form-control"
                                id="email"
                                name="email"
                                placeholder="Enter your email"
                                autofocus
                            />
                        </div>

                        {!showSpinner && <button class="btn btn-primary d-grid w-100" id="submit">Send Reset Code</button>}
                        {showSpinner && <center><RotatingLines width="35" strokeColor="#6495ED" strokeWidth="3" /></center>}
                    </form>
                    <div class="text-center">
                        <Link to="/">
                            <i class="bx bx-chevron-left scaleX-n1-rtl"></i>
                            Back to login

                        </Link>
                    </div>
                </div>
            </div>
        </ImageFlexContainer>
    );
}

export default ForgotPassword;