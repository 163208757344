import React from 'react'

function SubscriptionCard({label, value}) {
    return (
        <div style={{ padding: "16px 24px", backgroundColor: "#EDF2FC", borderRadius: "8px", minHeight: '100px', marginBottom: '20px'}}>
            <label style={{ fontSize: '16px', color: "#556275" }}>{label}</label>
            <p style={{ color: "#282F3A", fontSize: "36px", fontWeight: "500" }}>{value}</p>
        </div>
    )
}

export default SubscriptionCard