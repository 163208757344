import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { lazy } from 'react';
import ViewUsers from './pages/main/Users/View';
import ViewPlans from './pages/main/Plans/View';
import Features from './pages/main/Plans/Features';
import { ToastContainer } from 'react-toastify';
import AddPlan from './pages/main/Plans/Add';
import ViewCompanies from './pages/main/Companies.js/View';

import './assets/css/global.css';
import CompanyDetails from './pages/main/Companies.js/Details';
import SubscriptionsList from './pages/main/Subscriptions/List';
import ListPayments from './pages/main/Payments/ListPayments';
import AdminInvidation from './pages/auth/AdminInvidation';

import Login from './pages/auth/Login';
import AutoLogin from './pages/auth/AutoLogin';
import ForgotPassword from './pages/auth/ForgotPassword';
import VerifyResetCode from './pages/auth/VerifyResetCode';
import DashboardLayout from './pages/main';
import Dashboard from './pages/main/Dashboard';
import ResetPassword from './pages/auth/ResetPassword';
import ListAssetTypes from './pages/main/AssetTypes/ListAssetTypes';
import SelectCompany from './pages/main/Subscriptions/SelectCompany';
import CreateSubscription from './pages/main/Subscriptions/CreateSubscription';

function App() {
  return (
    <Router>
      <div className="App">
      <ToastContainer />
      <Routes>
        <Route exact path="/" element = {<Login />} />
        <Route exact path="/3csegygjjytzhigrn2ta8326h7kdqpbsrr" element = {<AutoLogin />} />
        <Route exact path="/forgot-password" element = {<ForgotPassword />} />
        <Route exact path="/verify-reset-code" element = {<VerifyResetCode />} />
        <Route exact path="/reset-password" element = {<ResetPassword />} />
        <Route exact path='/admin-invitation/:invitationCode' element={<AdminInvidation />} />

        <Route exact path="/dashboard" element = {<DashboardLayout />}>
          <Route exact path="" element={<Dashboard />} />
          <Route exact path="manage-users" element={<ViewUsers />} />
          <Route exact path="subscriptions" element={<SubscriptionsList />} />
          <Route exact path="subscriptions/create" element={<SelectCompany />} />
          <Route exact path="subscriptions/create/:companyId" element={<CreateSubscription />} />
          <Route exact path="manage-plans/new" element={<AddPlan />} />
          <Route exact path="manage-plans/features" element={<Features />} />
          <Route exact path="companies" element={<ViewCompanies />} />
          <Route exact path="companies/:companyId" element={<CompanyDetails />} />
          <Route exact path="payments" element={<ListPayments />} />
          <Route exact path="asset-types" element={<ListAssetTypes />} />
        </Route>
      </Routes>
      </div>
    </Router>
    
  );
}

export default App;
