import axios from 'axios';

import { URL as baseURL } from "../../AppParams";

const api = axios.create({
	baseURL,
	headers: {
		'Content-Type': 'application/json'
	},
});

api.interceptors.request.use(
	config => {
		const token = localStorage.getItem('rayda-admin-token')
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`
		}
		return config
	},
	error => Promise.reject(error)
);

export default api;