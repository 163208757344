import React, { useEffect } from 'react'
import { Row } from 'react-bootstrap'
import ReactDOMServer from 'react-dom/server';
import Currency from 'react-currency-formatter';

function CompanySubscriptions({subscriptions}) {

  const tableContent = () => {
		const dt_invoice_table = window.$('.view_subscriptions_table');
    dt_invoice_table.innerHTML = "";

		if (dt_invoice_table.length) {
			const dt_invoice = dt_invoice_table.DataTable({
				data: subscriptions,
				"bDestroy": true,

				columns: [
					{ data: 'plan' },
					{ data: 'invoice_number' },
					{ data: 'amount' },
					{ data: 'start_date' },
					{ data: 'end_date' },
					{ data: 'status' },
				],
				columnDefs: [
					{
						targets: 0,
						render: function (data, type, full, meta) {
							return `<div class="d-flex justify-content-start align-items-center">
								<div class="d-flex flex-column">
								<span>${full.plan}</span>
								</div>
								</div>`;
						}
					},
					{
						targets: 1,
						render: function (data, type, full, meta) {
							return `<div class="d-flex justify-content-start align-items-center">
								<div class="d-flex flex-column">
								<span>${full.code ? full.code : ''}</span>
								</div>
								</div>`;
						}
					},
					{
						targets: 2,
						render: function (data, type, full, meta) {
							return `<div class="d-flex justify-content-start align-items-center">
								<div class="d-flex flex-column">
								<span>${ReactDOMServer.renderToStaticMarkup(<Currency  quantity={full?.formatted_amount} currency='NGN'/>)}</span>
								</div>
								</div>`;
						}
					},
					{
						targets: 3,
						render: function (data, type, full, meta) {
							return `<div class="d-flex justify-content-start align-items-center">
								<div class="d-flex flex-column">
								<span>${full.start_date}</span>
								</div>
								</div>`;
						}
					},
					{
						targets: 4,
						render: function (data, type, full, meta) {
							return `<div class="d-flex justify-content-start align-items-center">
								<div class="d-flex flex-column">
								<span>${full.end_date ? full.end_date : ''}</span>
								</div>
								</div>`;
						}
					},
					{
						targets: 5,
						render: function (data, type, full, meta) {
							return `<div class="d-flex justify-content-start align-items-center">
							<div class="d-flex flex-column">
							<span class="badge rounded-pill ${full?.status == 'expired' ? 'bg-label-danger' : full?.status == 'pending' ? 'bg-label-warning' : 'bg-label-success'}">${full.status}</span>
							</div>
							</div>`;
						}
					},
				],
				order: [[1, 'asc']],
				dom:
					'<"row ms-2 me-3"' +
					'<"col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start gap-2"l<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start mt-md-0 mt-3"B>>' +
					'<"col-12 col-md-6 d-flex align-items-center justify-content-end flex-column flex-md-row pe-3 gap-md-2"f<"invoice_status mb-3 mb-md-0">>' +
					'>t' +
					'<"row mx-2"' +
					'<"col-sm-12 col-md-6"i>' +
					'<"col-sm-12 col-md-6"p>' +
					'>',
				language: {
					sLengthMenu: '_MENU_',
					search: '',
					searchPlaceholder: 'Search accounts'
				},

			});
		}

		// On each datatable draw, initialize tooltip
		dt_invoice_table.on('draw.dt', function () {
			var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
			var tooltipList = tooltipTriggerList?.map(function (tooltipTriggerEl) {
				return new window.bootstrap.Tooltip(tooltipTriggerEl, {
					boundary: document.body
				});
			});
		});

		setTimeout(() => {
			window.$('.dataTables_filter .form-control').removeClass('form-control-sm');
			window.$('.dataTables_length .form-select').removeClass('form-select-sm');
		}, 300);
	}

  useEffect(() => {
    tableContent();
  }, []);

  return (
    <div class="card mb-4">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5 class="mb-0">Subscriptions</h5>
      </div>

      <div class="card-datatable table-responsive">
        <table className="view_subscriptions_table table border-top" id="viewAssets_table">
          <thead>
            <tr>
              <th>PLAN</th>
              <th>INVOICE NUMBER</th>
              <th>AMOUNT</th>
              <th>SUBSCRIPTION DATE</th>
              <th>END DATE</th>
			  <th>STATUS</th>
            </tr>
          </thead>
        </table>

      </div>

    </div>
  )
}

export default CompanySubscriptions