import React from 'react'

function PaymentCard({label, value}) {
    return (
        <div style={{ padding: "16px 24px", backgroundColor: "#EDF2FC", borderRadius: "8px", minHeight: '100px', marginBottom: '20px' }}>
            <label style={{ fontSize: '16px', color: "#556275" }}>{label}</label>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", padding: "0px", gap: "8px"}}>
                <p style={{ color: "#282F3A", fontSize: "36px", fontWeight: "500" }}>{value}</p>
                <div style={{height: '44px', width: '48px', backgroundColor: "#638EE7", borderRadius: "5px", display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}>
                    <i class="bx bx-right-arrow-alt mx-1" style={{color: '#fff', fontSize: '30px'}}></i>
                </div>
            </div>
        </div>
    )
}

export default PaymentCard