import React, { useEffect, useState } from 'react';
import { AddFeature, AddSubscriptionPlan, FetchSubscriptionPlansFeatures } from '../../../utils/api/requests';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import InputField from '../../../components/InputField';
import joi from 'joi-browser';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { Oval } from "react-loader-spinner";

const AddPlan = () => {
    const [errors, setErrors] = useState({});
    const [all_features, setAllFeatures] = useState([]),
        [showSpinner, setShowSpinner] = useState(false);

    const [planData, setPlanData] = useState({
        plan_name: '', plan_type: '', monthly: '', annually: '', features: []
    })

    const { plan_name, plan_type, monthly, annually, features } = planData;

    const fetchFeatures = async () => {
        const { features } = await FetchSubscriptionPlansFeatures();

        setAllFeatures(features);
    }

    useEffect(() => {
        fetchFeatures();

    }, []);

    // Validate featureSchema
    const planSchema = {
        plan_name: joi.string().required().label("Plan Name"),
        plan_type: joi.string().required().label("Plan Type")
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        const temp = { ...planData };
        temp[name] = value;

        setPlanData(temp);
    }

    const handleFeaturesChange = (e) => {
        const { name, value } = e.target;

        const temp = { ...features };
        temp[name] = value;

        const tempData = { ...planData };
        tempData.features = temp;

        setPlanData(tempData);
    }

    const validateFeature = (state, objSchema) => {
        const options = { abortEarly: false };
        const { error } = joi.validate(state, objSchema, options);

        const errors = {};

        if (error) {
            for (let item of error.details) {
                if (item.message !== `"${item.path[0]}" is not allowed`) {
                    errors[item.path[0]] = item.message;

                    setErrors(errors);
                }

            }

        }

        return errors.length > 0 ? errors : null;
    }

    const handleAddPlan = async (e) => {
        try {
            e.preventDefault();

            const isValid = validateFeature(planData, planSchema);
            
            if (isValid) return;

            setShowSpinner(true);

            let temp_features = all_features.map(feature => {
                return {feature: feature.id, value: document.getElementById(`feature_${feature.id}`).value}
            });

            const { message } = await AddSubscriptionPlan({ name: plan_name, type: plan_type, monthly, annually, features: temp_features });

            toast.success(message);

            setPlanData({
                plan_name: '', plan_type: '', monthly: '', annually: '', features: []
            });

        } catch (error) {
            alert(JSON.stringify(error));
            toast.error(error);
        }finally{
            setShowSpinner(false);
        }

    }

    return (
        <div class="row p-3">
            <div class="col-md-12">
                <div class="card mb-4">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <div className='col-md-6'>
                            <h5 class="mb-0">New Subscription Plan</h5>
                        </div>
                        <div className='col-md-6'>

                        </div>
                    </div>

                    <div className='card-body'>
                        <form action='#' method='post' onSubmit={handleAddPlan}>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <InputField
                                        defaultInput={true}
                                        name="plan_name"
                                        label="Plan Name"
                                        inputType="text"
                                        id="plan_name"
                                        value={plan_name}
                                        placeholder="Plan Name"
                                        handleChange={handleChange}
                                        error={errors.plan_name}
                                    />

                                    <div class="col-md-12 mb-3">
                                        <label for="office" class="form-label">Type</label>

                                        <select className="form-control" name="plan_type" id="plan_type" onChange={handleChange}>
                                            <option value="" selected={plan_type === ''} disabled>Select type</option>
                                            <option value='Free' selected={plan_type === 'Free'}>Free</option>
                                            <option value='Paid' selected={plan_type === 'Paid'}>Paid</option>
                                        </select>
                                    </div>

                                    <InputField
                                        defaultInput={true}
                                        name="monthly"
                                        label="Monthly Fee"
                                        inputType="text"
                                        id="monthly"
                                        value={monthly}
                                        placeholder="Monthly Fee"
                                        handleChange={handleChange}
                                    />

                                    <InputField
                                        defaultInput={true}
                                        name="annually"
                                        label="Annual Fee"
                                        inputType="text"
                                        id="annually"
                                        value={annually}
                                        placeholder="Annual Fee"
                                        handleChange={handleChange}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <h5>Features</h5><hr />
                                    {
                                        all_features.map(feature => <InputField key={feature.id}
                                            defaultInput={true}
                                            name={`feature_${feature.id}`}
                                            label={feature.name}
                                            inputType="text"
                                            id={`feature_${feature.id}`}
                                            value={features[`feature_${feature.id}`]}
                                            placeholder={feature.name}
                                            handleChange={handleFeaturesChange}
                                        />)
                                    }
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "right", marginTop: "2rem" }}>
                                {!showSpinner && <button type="submit" className="btn btn-primary me-2" style={{ width: "16rem" }} >Add Plan</button>}
                                {showSpinner && <center><Oval ariaLabel="loading-indicator" width="35" secondaryColor="#6495ED" strokeWidth="3" /></center>}
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default AddPlan;