import React, { useEffect, useRef } from 'react'
import { removeValuesFromArray } from '../utils/otp.utils';


const PIN_MIN_VALUE = 0;
const PIN_MAX_VALUE = 10;
const BACKSPACE_KEY = 'Backspace';

const OtpInputGrid = (props) => {

  const { pinLength, pin, onPinChanged } = props;

  const inputRefs = useRef([]);

  const changePinFocus = (pinIndex) => {
    const ref = inputRefs.current[pinIndex];
    if (ref) {
      ref.focus();
    }
  };

  useEffect(() => {
    changePinFocus(0);
  }, []);


  const onChange = ( event, index ) => {
    const previousValue = event.target.defaultValue;
    const valuesArray = event.target.value.split('');
    removeValuesFromArray(valuesArray, previousValue);
    const value = valuesArray.pop();

    if (!value) {
      return;
    }

    const pinNumber = Number(value.trim());
    if (isNaN(pinNumber) || value.length === 0) {
      return;
    }

    if (pinNumber >= PIN_MIN_VALUE && pinNumber <= PIN_MAX_VALUE) {
      onPinChanged(pinNumber, index);
      if (index < pinLength - 1) {
        changePinFocus(index + 1);
      }
    }
  };


  const onKeyDown = (
    event,
    index
  ) => {
    const keyboardKeyCode = event.nativeEvent.code;
    if (keyboardKeyCode !== BACKSPACE_KEY) {
      return;
    }
    
    if (pin[index] === undefined) {
      changePinFocus(index - 1);
    } else {
      onPinChanged(undefined, index);
    }
  };
    return (
        <div className="d-flex justify-content-sm-between align-items-center">
            {
                Array.from({length: pinLength}, (_, index) => (
                    <input 
                        className="form-control auth-input h-px-50 numeral-mask h-px-50 text-center text-center my-2 mx-1"
                        onKeyDown={(event) => onKeyDown(event, index)}
                        key={index}
                        ref={(el) => {
                        if (el) {
                            inputRefs.current[index] = el;
                        }
                        }}
                        onChange={(event) => onChange(event, index)}
                        value={pin[index]}
                    />
                ))
            }
        </div>
    )
}

export default OtpInputGrid