import React, { useEffect, useState } from 'react';
import { config } from '../../../utils/config';
import wave1 from '../../../assets/img/wave1.png';
import wave2 from '../../../assets/img/wave2.png';
import { GetAnalyticsData } from '../../../utils/api/requests';
import ChartIndicator from '../../../components/ChartIndicator';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';

const Dashboard = () => {
	const [data, setData] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [insuranceTotal, setInsuranceTotal] = useState(0);

	const loadRevenueChart = () => {
		document.getElementById('donut-chart').innerHTML = "";

		var donutChartOption = {
			chart: {
				width: 180,
				type: 'donut',
			},
			dataLabels: {
				enabled: false
			},
			series: data?.revenue?.summary.map(revenue => revenue.total),
			labels: data?.revenue?.summary.map(revenue => revenue.currency),
			stroke: {
				width: 0,
				lineCap: 'round',
			},
			colors: [config.colors.primary, config.colors.warning],
			plotOptions: {
				pie: {
					donut: {
						size: '90%',
						labels: {
							show: true,
							name: {
								show: true,
								fontSize: '15px',
								colors: config.colors.sub_label_color,
								offsetY: 20,
								fontFamily: 'IBM Plex Sans',
							},
							value: {
								show: true,
								fontSize: '26px',
								fontFamily: 'Rubik',
								color: config.colors.label_color,
								offsetY: -20,
								formatter: function (val) {
									return val
								}
							},
							total: {
								show: true,
								label: 'Total',
								color: config.colors.gray_light,
								formatter: function (w) {
									return w.globals.seriesTotals.reduce(function (a, b) {
										return a + b
									}, 0)
								}
							}
						}
					}
				}
			},
			legend: {
				show: false
			}
		}

		var donutChart = new window.ApexCharts(
			document.querySelector("#donut-chart"),
			donutChartOption
		);

		donutChart.render();
	}

	const insuranceDonutChart = () => {
		document.getElementById('insurance-donut-chart').innerHTML = "";

		var donutChartOption = {
			chart: {
				width: 180,
				type: 'donut',
			},
			dataLabels: {
				enabled: false
			},
			series: [data?.insurances?.insured, data?.insurances?.uninsured],
			labels: ["Insured", "Uninsured"],
			stroke: {
				width: 0,
				lineCap: 'round',
			},
			colors: [config.colors.primary, config.colors.warning],
			plotOptions: {
				pie: {
					donut: {
						size: '90%',
						labels: {
							show: true,
							name: {
								show: true,
								fontSize: '15px',
								colors: config.colors.sub_label_color,
								offsetY: 20,
								fontFamily: 'IBM Plex Sans',
							},
							value: {
								show: true,
								fontSize: '26px',
								fontFamily: 'Rubik',
								color: config.colors.label_color,
								offsetY: -20,
								formatter: function (val) {
									return val
								}
							},
							total: {
								show: true,
								label: 'Total',
								color: config.colors.gray_light,
								formatter: function (w) {
									return w.globals.seriesTotals.reduce(function (a, b) {
										return a + b
									}, 0)
								}
							}
						}
					}
				}
			},
			legend: {
				show: false
			}
		}

		var donutChart = new window.ApexCharts(
			document.querySelector("#insurance-donut-chart"),
			donutChartOption
		);

		donutChart.render();
	}

	const revenueChart = () => {
		document.getElementById('analytics-bar-chart').innerHTML = "";

		var analyticsBarChartOptions = {
			chart: {
				height: 260,
				type: 'bar',
				toolbar: {
					show: false
				}
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '20%',
					endingShape: 'rounded'
				},
			},
			dataLabels: {
				enabled: false
			},
			colors: [config.colors.primary, '#B6CDF8'],
			fill: {
				type: 'gradient',
				gradient: {
					shade: 'light',
					type: "vertical",
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 70, 100]
				},
			},
			series: [{
				name: '2020',
				data: [80, 95, 150, 210, 140, 230, 300, 280, 130]
			}, {
				name: '2019',
				data: [50, 70, 130, 180, 90, 180, 270, 220, 110]
			}],
			xaxis: {
				categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
				axisBorder: {
					show: false
				},
				axisTicks: {
					show: false
				},
				labels: {
					style: {
						colors: config.colors.gray_light
					}
				}
			},
			yaxis: {
				min: 0,
				max: 300,
				tickAmount: 3,
				labels: {
					style: {
						color: config.colors.gray_light
					}
				}
			},
			legend: {
				show: false
			},
			tooltip: {
				y: {
					formatter: function (val) {
						return "$ " + val + " thousands"
					}
				}
			}
		}

		var analyticsBarChart = new window.ApexCharts(
			document.querySelector("#analytics-bar-chart"),
			analyticsBarChartOptions
		);

		analyticsBarChart.render();
	}

	const onboardingAnalytics = () => {
		document.getElementById('donut-success-chart').innerHTML = "";

		var donutSuccessChartOption = {
			chart: {
				width: 120,
				height: 120,
				type: 'donut'
			},
			dataLabels: {
				enabled: false
			},
			series: [data?.onboarding?.assets_added, data?.onboarding?.completed, data?.onboarding?.details_added, data?.onboarding?.invited_users],
			labels: ["Assets added", "Completed", "Details added", "Invited users"],
			stroke: {
				width: 2
			},
			colors: [config.colors.warning, config.colors.info, config.colors.primary, config.colors.danger],
			plotOptions: {
				pie: {
					offsetY: 0,
					donut: {
						size: '70%',
					}
				}
			},
			legend: {
				show: false
			}
		}
		var donutSuccessChart = new window.ApexCharts(
			document.querySelector("#donut-success-chart"),
			donutSuccessChartOption
		);
		donutSuccessChart.render();
	}

	const showAssetsChart = () => {
		document.getElementById('assets-chart').innerHTML = "";

		var donutChartOption = {
			chart: {
				width: 180,
				type: 'donut',
			},
			dataLabels: {
				enabled: false
			},
			series: data.assets?.details?.map(asset => asset.total),
			labels: data.assets?.details?.map(asset => asset.asset_type_details?.name),
			stroke: {
				width: 0,
				lineCap: 'round',
			},
			colors: [config.colors.primary, config.colors.info, config.colors.warning, config.colors.danger, config.colors.purple, config.colors.secondary, config.colors.success, config.colors.black, config.colors.headingColor],
			plotOptions: {
				pie: {
					donut: {
						size: '90%',
						labels: {
							show: true,
							name: {
								show: true,
								fontSize: '15px',
								colors: config.colors.sub_label_color,
								offsetY: 20,
								fontFamily: 'IBM Plex Sans',
							},
							value: {
								show: true,
								fontSize: '26px',
								fontFamily: 'Rubik',
								color: config.colors.label_color,
								offsetY: -20,
								formatter: function (val) {
									return val
								}
							},
							total: {
								show: false,
								label: 'Assets',
								color: config.colors.gray_light,
								formatter: function (w) {
									return w.globals.seriesTotals.reduce(function (a, b) {
										return a + b
									}, 0)
								}
							}
						}
					}
				}
			},
			legend: {
				show: false
			}
		}

		var donutChart = new window.ApexCharts(
			document.querySelector("#assets-chart"),
			donutChartOption
		);

		donutChart.render();
	}

	const showDocumentsChart = () => {
		document.getElementById('documents-donut-chart').innerHTML = "";

		var donutChartOption = {
			chart: {
				width: 180,
				type: 'donut',
			},
			dataLabels: {
				enabled: false
			},
			series: data.documents?.details?.map(document => document.total),
			labels: data.documents?.details?.map(document => document.type.title),
			stroke: {
				width: 0,
				lineCap: 'round',
			},
			colors: [config.colors.primary, config.colors.info, config.colors.warning, config.colors.danger, config.colors.purple, config.colors.secondary, config.colors.success, config.colors.black, config.colors.headingColor],
			plotOptions: {
				pie: {
					donut: {
						size: '90%',
						labels: {
							show: true,
							name: {
								show: true,
								fontSize: '15px',
								colors: config.colors.sub_label_color,
								offsetY: 20,
								fontFamily: 'IBM Plex Sans',
							},
							value: {
								show: true,
								fontSize: '26px',
								fontFamily: 'Rubik',
								color: config.colors.label_color,
								offsetY: -20,
								formatter: function (val) {
									return val
								}
							},
							total: {
								show: true,
								label: 'Documents',
								color: config.colors.gray_light,
								formatter: function (w) {
									return w.globals.seriesTotals.reduce(function (a, b) {
										return a + b
									}, 0)
								}
							}
						}
					}
				}
			},
			legend: {
				show: false
			}
		}

		var donutChart = new window.ApexCharts(
			document.querySelector("#documents-donut-chart"),
			donutChartOption
		);

		donutChart.render();
	}

	const showInsuranceBoxChart = () => {
		document.getElementById('insurance-radial-chart').innerHTML = "";

		var multiRadialOptions = {
			chart: {
				height: 210,
				type: "radialBar",
			},
			colors: [config.colors.primary, config.colors.info, config.colors.warning, config.colors.danger, config.colors.purple, config.colors.secondary, config.colors.success, config.colors.black, config.colors.headingColor],
			series: [data.insurances?.active_insurance, data.insurances?.expired_insurance, data.insurances?.external_insurance],
			plotOptions: {
				radialBar: {
					offsetY: -10,
					hollow: {
						size: "40%"
					},
					track: {
						margin: 10,
						background: '#fff',
					},
					dataLabels: {
						name: {
							fontSize: '15px',
							colors: [config.colors.secondary],
							fontFamily: "IBM Plex Sans",
							offsetY: 25,
						},
						value: {
							fontSize: '30px',
							fontFamily: "Rubik",
							offsetY: -15,
						},
						total: {
							show: true,
							label: 'Insurance',
							fontSize: '15px',
							fontWeight: 400,
							fontFamily: "IBM Plex Sans",
							color: config.colors.secondary
						}
					}
				}
			},
			stroke: {
				lineCap: "round",
			},
			labels: ['Active Insurance', 'Expired Insurance', 'External Insurance']
		};

		var multiradialChart = new window.ApexCharts(
			document.querySelector("#insurance-radial-chart"),
			multiRadialOptions
		);
		multiradialChart.render();
	}

	const getDashboardData = async () => {
		if (loaded) {
			return 0;
		}

		const { data } = await GetAnalyticsData();

		console.log('data', data);
		setData(data);
		setLoaded(true);

		setInsuranceTotal(data.insurances?.active_insurance + data.insurances?.expired_insurance + data.insurances?.expired_insurance);
	}

	useEffect(() => {
		getDashboardData();
	}, []);

	useEffect(() => {
		loadRevenueChart();
		revenueChart();
		onboardingAnalytics();
		showAssetsChart();
		showDocumentsChart();
		showInsuranceBoxChart();
		insuranceDonutChart();
	}, [data]);

	return (
		<div className="container-xxl flex-grow-1 container-p-y">
			<h4 className="breadcrumb-wrapper py-3 mb-4">
				Dashboard
			</h4>
			<div class="row">
				<div class="col-md-4">
					<div class="card h-100">
						<div class="card-body donut-chart-wrapper">
							<div className='row' style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
								<div className='col-md-5'>
									<div id="donut-chart" class="d-flex justify-content-center"></div>
								</div>
								<div className='col-md-7'>
									<p>Total Revenue</p>
									<h4>{data?.revenue?.summary.map((revenue, key) => {
										let text = ""
										let totalAmount = revenue.amount;

										if (key === 0) {
											text =
												(revenue.currency == 'NGN' ? '₦' : (revenue.currency == 'USD' ? '$' : revenue.currency))
												+ (totalAmount >= 1000 ? (Math.floor(totalAmount / 1000) < 1000 ? `${Math.floor(totalAmount / 1000)}k` : `${Math.floor(totalAmount / 1000000)}M`) : totalAmount);
										} else {
											text = ' / ' +
												(revenue.currency == 'NGN' ? '₦' : (revenue.currency == 'USD' ? '$' : revenue.currency))
												+ (totalAmount >= 1000 ? (Math.floor(totalAmount / 1000) < 1000 ? `${Math.floor(totalAmount / 1000)}k` : `${Math.floor(totalAmount / 1000000)}M`) : totalAmount);
										}

										return text;
									})}</h4>
								</div>
							</div>

						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="card h-100">
						<div class="card-body d-flex align-items-center justify-content-between">
							<div class="d-flex align-items-center">
								<div class="avatar bg-rgba-warning m-0 p-2 mr-3" style={{ height: '50px', width: '50px' }}>
									<div class="avatar-content">
										<i class="bx bx-user text-warning font-medium-2" style={{ fontSize: '28px' }}></i>
									</div>
								</div>
								<div class="total-amount">
									<h5 class="mb-0">{data?.subscriptions?.total}</h5>
									<small class="text-muted">Subcriptions Active</small>
								</div>
							</div>
							<div><img src={wave2} /></div>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="card h-100">
						<div class="card-body d-flex align-items-center justify-content-between">
							<div class="d-flex align-items-center">
								<div class="avatar bg-rgba-primary m-0 p-2 mr-75 mr-xl-2" style={{ height: '50px', width: '50px' }}>
									<div class="avatar-content">
										<i class="bx bx-user text-primary" style={{ fontSize: '28px' }}></i>
									</div>
								</div>
								<div class="total-amount">
									<h5 class="mb-0">{data?.accounts?.total}</h5>
									<small class="text-muted">Accounts Active</small>
								</div>
							</div>
							<div><img src={wave1} /></div>
						</div>
					</div>
				</div>
			</div>
			<div className='row pt-4'>
				<div className='col-md-5'>
					<div className='row h-50'>
						<div class="col-md-6 pb-3">
							<div class="card h-100">
								<div class="card-body donut-chart-wrapper">
									<div className='row' style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
										<div className='col-md-12'>
											<p>Total Companies</p>
											<h4>{data?.companies?.total}</h4>
										</div>
									</div>

								</div>
							</div>
						</div>
						<div class="col-md-6 pb-3">
							<div class="card  h-100">
								<div class="card-body donut-chart-wrapper">
									<div className='row' style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
										<div className='col-md-12'>
											<p>Total Assets</p>
											<h4>{data?.assets?.total}</h4>
											{/* <i class="bx bx-trending-up text-success align-middle mr-50"></i> */}
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
					<div className='row h-50'>
						<div class="col-md-12">
							<div class="card h-100">
								<div class="card-body donut-chart-wrapper">
									<div className='row' style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
										<div className='col-md-5'>
											<div id="insurance-donut-chart" class="d-flex justify-content-center"></div>
										</div>
										<div className='col-md-7'>
											<h3>Insurance</h3>
											<div className='row'>
												<div className='col-md-6'>
													<ChartIndicator color="#5a8dee" />
													<p>Insured ({data?.insurances?.insured})</p>
												</div>
												<div className='col-md-6'>
													<ChartIndicator color="#fdac41" />
													<p>Uninsured ({data?.insurances?.uninsured})</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-md-7'>
					<div className='row'>
						<div class="col-sm-12">
							<div class="card">
								<div class="card-header d-flex justify-content-between align-items-center">
									<h4 class="card-title">Revenue</h4>
									<i class="bx bx-dots-vertical-rounded font-medium-3 cursor-pointer"></i>
								</div>
								<div class="card-body pb-1">
									<div class="d-flex justify-content-around align-items-center flex-wrap">
										<div class="user-analytics mr-2">
											<span class="align-middle text-muted">Current Month</span>
											<div class="d-flex">
												<div id="radial-success-chart"></div>
												<h3 class="mt-1 ml-50">$45,320</h3>
											</div>
										</div>
										<div class="sessions-analytics mr-2">
											<span class="align-middle text-muted">Previous Month</span>
											<div class="d-flex">
												<div id="radial-warning-chart"></div>
												<h3 class="mt-1 ml-50">$58,610</h3>
											</div>
										</div>
									</div>
									<div id="analytics-bar-chart" class="my-75"></div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
			<div className='row pt-4'>
				<div class="col-12 dashboard-marketing-campaign">
					<div class="card marketing-campaigns">
						<div class="card-header d-flex justify-content-between align-items-center pb-1">
							<h4 class="card-title">Onboarding Analytics</h4>
							<i class="bx bx-dots-vertical-rounded font-medium-3 cursor-pointer"></i>
						</div>
						<div class="card-body pb-0">
							<div class="row mb-1">
								<div class="col-md-10 col-12">
									<div className='row'>
										<div className='col-md-2'>
											<div class="d-inline-block">
												<div class="d-flex market-statistics-1">
													<div id="donut-success-chart" class="mx-1"></div>
												</div>
											</div>
										</div>
										<div className='col-md-9'>
											<div className='row pt-3'>
												<div className='col-md-3'>
													<ChartIndicator color={config.colors.warning} />
													<p>Assets added ({data?.onboarding?.assets_added})</p>
												</div>
												<div className='col-md-3'>
													<ChartIndicator color={config.colors.info} />
													<p>Completed ({data?.onboarding?.completed})</p>
												</div>
												<div className='col-md-3'>
													<ChartIndicator color={config.colors.primary} />
													<p>Details added ({data?.onboarding?.details_added})</p>
												</div>
												<div className='col-md-3'>
													<ChartIndicator color={config.colors.danger} />
													<p>Invited users ({data?.onboarding?.invited_users})</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-2 col-12 text-md-right">
									<button class="btn btn-sm btn-primary glow mt-md-2 mb-1">View Report</button>
								</div>
							</div>
						</div>
						<div class="table-responsive">
							<table id="table-marketing-campaigns" class="table table-borderless table-marketing-campaigns mb-0">
								<thead>
									<tr>
										<th>Company</th>
										<th>Assets</th>
										<th>Personnel</th>
										<th>Insured Assets</th>
										<th>Industry</th>
										<th>Status</th>
									</tr>
								</thead>
								<tbody>
									{
										data?.onboarding?.details?.map(company => {
											return (
												<tr key={company.id}>
													<td class="py-1 line-ellipsis">
														{company.name}
													</td>
													<td class="py-1">
														{company.assets}
													</td>
													<td class="py-1">{company.personnels}</td>
													<td>{company.insured_assets}</td>
													<td>{company.industry}</td>
													<td>
														<span class={`badge rounded-pill 
														${company.status == 'completed' ? 'bg-label-success' : company.status == 'users' ? 'bg-label-secondary' : company.status == 'devices' ? 'bg-label-info' : 'bg-label-warning'}
														`}>{company.status}</span>
													</td>
												</tr>
											)
										})
									}

								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div className='row pt-4'>
				<div class="col-md-4">
					<div class="card">
						<div class="card-header">
							<h5 class="card-title">
								Documents
								<p style={{ fontSize: '38px' }}>{data.documents?.total}</p>
							</h5>
						</div>
						<div class="card-body">
							<div id="documents-donut-chart" class="d-flex justify-content-center"></div>
							<table className='table table-borderless'>
								<tbody>
									{
										data.documents?.details?.map(document => {
											return (
												<tr>
													<td>{capitalizeFirstLetter(document.type.title)}</td>
													<td>{document.total}</td>
													<td>{((document.total / data.documents.total) * 100).toFixed(2)}%</td>
												</tr>
											)
										})
									}

								</tbody>
							</table>
						</div>
					</div>
				</div>

				<div class="col-md-4">
					<div class="card">
						<div class="card-header">
							<h5 class="card-title">
								Insurances
								<p style={{ fontSize: '38px' }}>{data.insurances?.total}</p>
							</h5>
						</div>
						<div class="card-body">
							<div id="insurance-radial-chart" class="d-flex justify-content-center"></div>
							<table className='table table-borderless'>
								<tbody>
									<tr>
										<td>Active Insurances</td>
										<td>{data.insurances?.active_insurance}</td>
										<td>{insuranceTotal > 0 ? (((data.insurances?.active_insurance / insuranceTotal) * 100).toFixed(2)) : 0}%</td>
									</tr>
									<tr>
										<td>Expired Insurances</td>
										<td>{data.insurances?.expired_insurance}</td>
										<td>{insuranceTotal > 0 ? (((data.insurances?.expired_insurance / insuranceTotal) * 100).toFixed(2)) : 0}%</td>
									</tr>
									<tr>
										<td>External Insurances</td>
										<td>{data.insurances?.external_insurance}</td>
										<td>{insuranceTotal > 0 ? (((data.insurances?.external_insurance / insuranceTotal) * 100).toFixed(2)) : 0}%</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<div class="col-md-4 dashboard-visit">
					<div class="card">
						<div class="card-header d-flex justify-content-between align-items-center">
							<h5 class="card-title">
								Assets
								<p style={{ fontSize: '38px' }}>{data.assets?.total}</p>
							</h5>
						</div>
						<div class="card-body">
							<div id="assets-chart" class="d-flex justify-content-center"></div>
							<table className='table table-borderless'>
								<tbody>
									{
										data.assets?.details?.map(asset => {
											return (
												<tr>
													<td>{asset?.asset_type_details?.name}</td>
													<td>{asset?.total}</td>
													<td>{((asset?.total / data?.assets?.total) * 100).toFixed(2)}%</td>
												</tr>
											)
										})
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Dashboard;