import { LoadingCellRenderer } from '@ag-grid-community/core/dist/cjs/es5/rendering/cellRenderers/loadingCellRenderer';
import React, { useEffect, useState } from 'react'
import { Oval, RotatingLines } from 'react-loader-spinner';
import PaymentCard from '../../../components/PaymentCard';
import { AddAssetType, DeleteAssetType, FetchAssetTypes, FetchPayment, FetchPayments, FetchSubscription, FetchSubscriptions, UpdateAssetType, UpdateAssetTypeStatus } from '../../../utils/api/requests';
import { config } from '../../../utils/config';
import SubscriptionCard from '../../../components/SubscriptionCard';
import InputField from '../../../components/InputField';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";

function ListAssetTypes() {
    const [assetTypes, setAssetTypes] = useState([]),
        [assetType, setAssetType] = useState(),
        [updateAssetType, setUpdateAssetType] = useState(),
        [updateAssetTypeId, setUpdateAssetTypeId] = useState(),
        [loading, setLoading] = useState(true),
        [selectedPayment, setSelectedPayment] = useState([]),
        [fetching, setFetching] = useState(false);

    const getAssetTypes = async () => {
        try {
            const { data } = await FetchAssetTypes();

            setAssetTypes(data);

        } catch (error) {
            // console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const createNewAssetType = async (e) => {
        e.preventDefault();

        try {
            if (!assetType) {
                toast.error('Type in the asset type name');
                return;
            }

            const { message, data } = await AddAssetType({ asset_type: assetType });

            getAssetTypes();

            setAssetType("");

            toast.success(message);
        } catch (error) {
            toast.error(error.message);
        }
    }

    const updateAssetTypeAction = async (e) => {
        e.preventDefault();

        try {
            if (!updateAssetType) {
                toast.error('Type in the asset type name');
                return;
            }

            const { message, data } = await UpdateAssetType({ assetTypeId: updateAssetTypeId, assetType: updateAssetType });

            getAssetTypes();

            toast.success(message);
        } catch (error) {

        }
    }

    const deleteAssetType = async (assetTypeId) => {
        try {
            const { message, data } = await DeleteAssetType({ assetTypeId });

            getAssetTypes();

            toast.success(message);
        } catch (error) {

        }
    }

    const changeAssetTypeStatus = async (e) => {
        const switchBtn = e.target;

        const action = switchBtn.value === 'on' ? 'disable' : 'enable';
        const asset_type = switchBtn.id.split('_')[1];

        try {
            const { data } = await UpdateAssetTypeStatus({ asset_type, action });

            getAssetTypes();

        } catch (error) {

        }
    }

    useEffect(() => {
        getAssetTypes();
    }, []);

    if (loading) {
        return (
            <center><RotatingLines width="35" strokeColor="#6495ED" strokeWidth="3" /></center>
        )
    }

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="breadcrumb-wrapper py-3 mb-4">
                Asset Types
            </h4>
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-4">
                        <div class="card-header d-flex justify-content-between align-items-center">
                            <div className='col-md-6'><h5 class="mb-0">Asset Types</h5></div>
                            <div className='col-md-6'>
                                <a href='#' className='btn btn-primary float-right' data-bs-placement="top" data-bs-toggle="offcanvas" data-bs-target="#offcanvasViewUser" style={{ float: 'right' }}>Add Asset Type</a>

                            </div>
                        </div>

                        <div className='card-body'>
                            <div class="card-datatable table-responsive">
                                <table class="invoice-list-table table table-bordered" id="view_users_table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>TYPES</th>
                                            <th>STATUS</th>
                                            <th>ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            assetTypes.map((type, key) => {
                                                return (
                                                    <tr>
                                                        <td>{key + 1}</td>
                                                        <td>{type.name}</td>
                                                        <td>{type.is_active ? 'Active' : 'Inactive'}</td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <Form.Check
                                                                    type="switch"
                                                                    id={`sw_${type.id}`}
                                                                    label=""
                                                                    checked={type.is_active}
                                                                    onChange={changeAssetTypeStatus}
                                                                    value={type.is_active ? 'on' : 'off'}
                                                                />
                                                                <div className="dropdown">
                                                                    <div className="btn dropdown-toggle hide-arrow text-body p-0" data-bs-toggle="dropdown">
                                                                        <i className="bx bx-dots-vertical-rounded"></i>
                                                                    </div>

                                                                    <div className="dropdown-menu dropdown-menu-end" >

                                                                        <div className="dropdown-item"
                                                                             data-bs-placement="top" data-bs-toggle="offcanvas" data-bs-target="#offcanvasUpdateAssetType"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => { setUpdateAssetType(type.name); setUpdateAssetTypeId(type.id) }}>
                                                                            Edit
                                                                        </div>

                                                                        <div className="dropdown-divider"></div>
                                                                        <div className="dropdown-item text-danger delete-record" style={{ cursor: "pointer" }} 
                                                                        onClick={() => {deleteAssetType(type.id)}}
                                                                        >Delete</div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="offcanvas offcanvas-end"
                    tabindex="-1"
                    id="offcanvasViewUser"
                    aria-labelledby="offcanvasAddViewLabel"
                >
                    <div class="offcanvas-header border-bottom">
                        <h6 id="offcanvasAddViewLabel" class="offcanvas-title">Add Asset Type</h6>
                        <button
                            type="button"
                            class="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="offcanvas-body flex-grow-0 mx-0">

                        <form action='#' method='post' onSubmit={createNewAssetType}>
                            <div className='row'>
                                <InputField
                                    defaultInput={true}
                                    name="assetType"
                                    label="Asset Type"
                                    inputType="text"
                                    id="assetType"
                                    value={assetType}
                                    placeholder=""
                                    handleChange={() => {
                                        setAssetType(document.getElementById('assetType').value)
                                    }}
                                />

                                <div className='col-md-12'>
                                    <button type='submit' className='btn btn-primary'>Create</button>
                                </div>
                            </div>

                        </form>

                    </div>
                </div>

                <div
                    class="offcanvas offcanvas-end"
                    tabindex="-1"
                    id="offcanvasUpdateAssetType"
                    aria-labelledby="offcanvasAddViewLabel"
                >
                    <div class="offcanvas-header border-bottom">
                        <h6 id="offcanvasAddViewLabel" class="offcanvas-title">Update Asset Type</h6>
                        <button
                            type="button"
                            class="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="offcanvas-body flex-grow-0 mx-0">

                        <form action='#' method='post' onSubmit={updateAssetTypeAction}>
                            <div className='row'>
                                <InputField
                                    defaultInput={true}
                                    name="assetTypeUpdate"
                                    label="Asset Type"
                                    inputType="text"
                                    id="assetTypeUpdate"
                                    value={updateAssetType}
                                    placeholder=""
                                    handleChange={() => {
                                        setUpdateAssetType(document.getElementById('assetTypeUpdate').value)
                                    }}
                                />

                                <div className='col-md-12'>
                                    <button type='submit' className='btn btn-primary'>Update</button>
                                </div>
                            </div>

                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default ListAssetTypes