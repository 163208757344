import Auth from "../../components/Auth";
import Logo from './../../assets/img/app_logo.png';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import PasswordChecklist from "react-password-checklist";
import React, { useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { ResetUserPassword } from "../../utils/api/requests";

const ResetPassword = () => {
    const navigate = useNavigate();

    const location = useLocation();

    const [password, setPassword] = useState("")
    const [passwordAgain, setPasswordAgain] = useState("")
    const [isPasswordValid, setPasswordValid] = useState(false);

    const [showSpinner, setShowSpinner] = useState(false);

    const resetUserPassword = async (e) => {
        e.preventDefault();

        let password = document.getElementById('password').value;

        if (!isPasswordValid) {
            toast.error('Provide a valid password');
            return;
        }

        try {
            setShowSpinner(true);

            const send_data = { email: location.state.email, "password": password, otp: location.state.otp };

            const { message } = await ResetUserPassword(send_data);

            toast.success(message);

            navigate('/', { replace: true });

        } catch (error) {
            toast.error(error.message);
        } finally {
            setShowSpinner(false);
        }
    }

    return (
        <Auth>
            <div className="col-md-4 offset-md-4">
                <div class="card">
                    <div class="card-body">

                        <div class="app-brand justify-content-center">
                            <a href="#" class="app-brand-link gap-2">
                                <img src={Logo} style={{ height: '50px' }} />
                            </a>
                        </div>

                        <center>
                            <h4 class="mb-2">Password Reset</h4>
                            <p class="mb-4">Type in a new passoword below</p>
                        </center>

                        <form class="mb-3" action="#" method="POST" onSubmit={resetUserPassword}>
                            <div class="form-password-toggle mb-3">
                                <label class="form-label" for="password">Password</label>
                                <div class="input-group input-group-merge">
                                    <input type="password" onChange={e => setPassword(e.target.value)} id="password" class="form-control" name="password" placeholder="Password" aria-describedby="password" />
                                    <span class="input-group-text cursor-pointer"><i class="bx bx-hide"></i></span>
                                </div>
                            </div>
                            <div class="form-password-toggle mb-3">
                                <label class="form-label" for="confirm_password">Confirm Password</label>
                                <div class="input-group input-group-merge">
                                    <input type="password" onChange={e => setPasswordAgain(e.target.value)} id="confirm_password" class="form-control" name="confirm_password" placeholder="Confirm Password" aria-describedby="confirm_password" />
                                    <span class="input-group-text cursor-pointer"><i class="bx bx-hide"></i></span>
                                </div>
                            </div>

                            <PasswordChecklist
                                rules={["minLength", "specialChar", "number", "capital", "match"]}
                                minLength={8}
                                value={password}
                                valueAgain={passwordAgain}
                                iconSize={12}
                                messages={{
                                    minLength: "Password must minimum of 8 characters long",
                                    specialChar: "Password must contain a special character",
                                    number: "Password must contain a number",
                                    capital: "Password must contain an uppercase letter",
                                    match: "Password and confirm password values must match",
                                }}
                                onChange={
                                    (isValid) => {
                                        setPasswordValid(isValid);
                                    }
                                }

                            />

                            {!showSpinner && <button class="btn btn-primary d-grid w-100" id="submit">Reset Password</button>}
                            {showSpinner && <center><RotatingLines width="35" strokeColor="#6495ED" strokeWidth="3" /></center>}
                        </form>

                        <p class="text-center">
                            <span>Remembered your password? </span>
                            <Link to="/">
                                <span>Sign in instead</span>

                            </Link>
                        </p>

                    </div>
                </div>
            </div>
        </Auth>
    );
}

export default ResetPassword;