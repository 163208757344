import React, { useEffect, useState } from "react";
import { NavLink as Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";

import Logo from '../../assets/img/logo-dark.svg';

const DashboardLayout = () => {
	const search = useLocation().search,
		status = new URLSearchParams(search).get('redirect_status');

	const {pathname} = useLocation();

	const navigate = useNavigate();

	const [accountOwner, setAccountOwner] = useState(undefined),
		[avatar, setAvatar] = useState('');

	const handleLogoutUser = (e) => {
		try {
			e.preventDefault();
			localStorage.removeItem("rayda-admin-token");

			window.location.pathname = "/";
		} catch ({ message }) {
			toast.error(`${message}`);
		}
	}

	useEffect(() => {
		(function () {
			let layoutMenuEl = document.querySelectorAll('#layout-menu');

			let menuToggler = document.querySelectorAll('.layout-menu-toggle');
			menuToggler.forEach(item => {
				item.addEventListener('click', event => {
					event.preventDefault();
					window.Helpers.toggleCollapsed();
				});
			});

		})();

		setAvatar(`Admin`);
	}, []);


	useEffect(() => {
		const scrollTopFn = () => {
			if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
				document.getElementById('layout-navbar').classList.add('navbar-elevated');
			} else {
				document.getElementById('layout-navbar').classList.remove('navbar-elevated');
			}
		}
		window.onscroll = () => {
			scrollTopFn();
		};
	}, []);

	return (
		<div className="layout-wrapper layout-content-navbar">
			<div className="layout-container">
				<aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
					<div className="app-brand demo pt-3 mb-2">
						<Link to="/" className="app-brand-link gap-2 me-4">
							<span className="app-brand-logo demo">
								<img src={Logo} height="100%" width="100%" />
							</span>
						</Link>

						<a href="javascript:void(0);" className="layout-menu-toggle menu-link text-large ms-auto">
							<i className="bx bx-x bx-sm d-xl-none d-block align-middle"></i>
						</a>
					</div>

					<div className="menu-divider mt-0"></div>

					<div className="menu-inner-shadow"></div>

					<ul className="menu-inner py-1">
						<li className={`menu-item ${pathname === '/dashboard' ? 'active' : ''}`}>
							<Link to="/dashboard" className="menu-link">
								<i className="menu-icon tf-icons bx bx-home-circle"></i>
								<div data-i18n="Dashboards">Dashboard</div>
							</Link>
						</li>
						<li className={`menu-item ${pathname === '/dashboard/companies' ? 'active' : ''}`}>
							<Link to="/dashboard/companies" className="menu-link">
								<i className="menu-icon tf-icons bx bx-briefcase-alt"></i>
								<div data-i18n="Manage Users">Manage Companies</div>
							</Link>
						</li>
						<li className={`menu-item ${pathname === '/dashboard/manage-users' ? 'active' : ''}`}>
							<Link to="/dashboard/manage-users" className="menu-link">
								<i className="menu-icon tf-icons bx bx-user-circle"></i>
								<div data-i18n="Manage Users">Manage Users</div>
							</Link>
						</li>

						<li className={`menu-item ${pathname === '/dashboard/subscriptions' ? 'active' : ''}`}>
							<Link to="/dashboard/subscriptions" className="menu-link">
								<i className="menu-icon tf-icons bx bx-box"></i>
								<div data-i18n="Manage Plans">Manage Subscriptions</div>
							</Link>
						</li>

						<li className={`menu-item ${pathname === '/dashboard/payments' ? 'active' : ''}`}>
							<Link to="/dashboard/payments" className="menu-link">
								<i className="menu-icon tf-icons bx bx-cart"></i>
								<div data-i18n="Personnel">Payment History</div>
							</Link>
						</li>
						<li className={`menu-item ${pathname === '/dashboard/asset-types' ? 'active' : ''}`}>
							<Link to="/dashboard/asset-types" className="menu-link">
								<i className="menu-icon tf-icons bx bx-cog"></i>
								<div data-i18n="Asset Types">Asset Types</div>
							</Link>
						</li>

					</ul>
				</aside>

				<div className="layout-page">

					<nav className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme" id="layout-navbar">
						<div className="container-fluid">
							<div className="layout-menu-toggle navbar-nav d-xl-none align-items-xl-center me-3 me-xl-0">
								<a className="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
									<i className="bx bx-menu bx-sm"></i>
								</a>
							</div>

							<div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
								<div className="navbar-nav align-items-center">
									<div className="nav-item navbar-search-wrapper mb-0">
										<a className="nav-item nav-link search-toggler px-0" href="javascript:void(0);">
											<i className="bx bx-search-alt bx-sm"></i>
											<span className="d-none d-md-inline-block text-muted">Search (Ctrl+/)</span>
										</a>
									</div>
								</div>

								<ul className="navbar-nav flex-row align-items-center ms-auto">
									<li className="nav-item me-2 me-xl-0">
										<a className="nav-link style-switcher-toggle hide-arrow" href="javascript:void(0);">
											<i className="bx bx-sm"></i>
										</a>
									</li>

									<li className="nav-item dropdown-shortcuts navbar-dropdown dropdown me-2 me-xl-0">
										<a
											className="nav-link dropdown-toggle hide-arrow"
											href="javascript:void(0);"
											data-bs-toggle="dropdown"
											data-bs-auto-close="outside"
											aria-expanded="false"
										>
											<i className="bx bx-grid-alt bx-sm"></i>
										</a>
										<div className="dropdown-menu dropdown-menu-end py-0">
											<div className="dropdown-menu-header border-bottom">
												<div className="dropdown-header d-flex align-items-center py-3">
													<h5 className="text-body me-auto mb-0">Shortcuts</h5>
													<a
														href="javascript:void(0)"
														className="dropdown-shortcuts-add text-body"
														data-bs-toggle="tooltip"
														data-bs-placement="top"
														title="Add shortcuts"
													><i className="bx bx-sm bx-plus-circle"></i
													></a>
												</div>
											</div>
											<div className="dropdown-shortcuts-list scrollable-container">
												<div className="row row-bordered overflow-visible g-0">
													<div className="dropdown-shortcuts-item col">
														<span className="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
															<i className="bx bx-calendar fs-4"></i>
														</span>
														<a href="app-calendar.html" className="stretched-link">Calendar</a>
														<small className="text-muted mb-0">Appointments</small>
													</div>
													<div className="dropdown-shortcuts-item col">
														<span className="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
															<i className="bx bx-food-menu fs-4"></i>
														</span>
														<a href="app-invoice-list.html" className="stretched-link">Invoice App</a>
														<small className="text-muted mb-0">Manage Accounts</small>
													</div>
												</div>
												<div className="row row-bordered overflow-visible g-0">
													<div className="dropdown-shortcuts-item col">
														<span className="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
															<i className="bx bx-user fs-4"></i>
														</span>
														<a href="app-user-list.html" className="stretched-link">User App</a>
														<small className="text-muted mb-0">Manage Users</small>
													</div>
													<div className="dropdown-shortcuts-item col">
														<span className="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
															<i className="bx bx-check-shield fs-4"></i>
														</span>
														<a href="app-access-roles.html" className="stretched-link">Role Management</a>
														<small className="text-muted mb-0">Permission</small>
													</div>
												</div>
												<div className="row row-bordered overflow-visible g-0">
													<div className="dropdown-shortcuts-item col">
														<span className="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
															<i className="bx bx-pie-chart-alt-2 fs-4"></i>
														</span>
														<a href="index.html" className="stretched-link">Dashboard</a>
														<small className="text-muted mb-0">User Profile</small>
													</div>
													<div className="dropdown-shortcuts-item col">
														<span className="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
															<i className="bx bx-cog fs-4"></i>
														</span>
														<a href="pages-account-settings-account.html" className="stretched-link">Setting</a>
														<small className="text-muted mb-0">Account Settings</small>
													</div>
												</div>
												<div className="row row-bordered overflow-visible g-0">
													<div className="dropdown-shortcuts-item col">
														<span className="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
															<i className="bx bx-help-circle fs-4"></i>
														</span>
														<a href="pages-help-center-landing.html" className="stretched-link">Help Center</a>
														<small className="text-muted mb-0">FAQs & Articles</small>
													</div>
													<div className="dropdown-shortcuts-item col">
														<span className="dropdown-shortcuts-icon bg-label-secondary rounded-circle mb-2">
															<i className="bx bx-window-open fs-4"></i>
														</span>
														<a href="modal-examples.html" className="stretched-link">Modals</a>
														<small className="text-muted mb-0">Useful Popups</small>
													</div>
												</div>
											</div>
										</div>
									</li>

									<li className="nav-item navbar-dropdown dropdown-user dropdown">
										<a className="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
											<div class="avatar me-2 avatar-online">
												<span class="avatar-initial bg-primary rounded-circle">{avatar}</span>
											</div>
										</a>
										<ul className="dropdown-menu dropdown-menu-end">
											<li>
												<a className="dropdown-item" href="pages-account-settings-account.html">
													<div className="d-flex">
														<div className="flex-shrink-0 me-3">
															<div class="avatar me-2 avatar-online">
																<span class="avatar-initial bg-primary rounded-circle">{avatar}</span>
															</div>
														</div>
														<div className="flex-grow-1">
															<span className="lh-1 d-block fw-semibold">{`${accountOwner?.first_name} ${accountOwner?.last_name}`}</span>
															<small>{accountOwner?.type}</small>
														</div>
													</div>
												</a>
											</li>
											<li>
												<div className="dropdown-divider"></div>
											</li>
											<li>
												<Link className="dropdown-item" to="/dashboard/user-profile">
													<i className="bx bx-user me-2"></i>
													<span className="align-middle">My Profile</span>
												</Link>
											</li>

											<li>
												<div className="dropdown-divider"></div>
											</li>

											<li>
												<a className="dropdown-item" href="#" onClick={handleLogoutUser}>
													<i className="bx bx-power-off me-2"></i>
													<span className="align-middle">Log Out</span>
												</a>
											</li>
										</ul>
									</li>
								</ul>
							</div>

							<div className="navbar-search-wrapper search-input-wrapper d-none">
								<input
									type="text"
									className="form-control search-input container-fluid border-0"
									placeholder="Search..."
									aria-label="Search..."
								/>
								<i className="bx bx-x bx-sm search-toggler cursor-pointer"></i>
							</div>
						</div>
					</nav>

					<Outlet />

					<footer className="content-footer footer bg-footer-theme">
						<div className="container-xxl d-flex flex-column flex-md-row flex-wrap justify-content-between py-2">
							<div className="mb-2 mb-md-0">
								© {new Date().getFullYear()} <a href="#" target="_blank" className="footer-link fw-semibold">Rayda</a>
							</div>

						</div>
					</footer>

					<div className="content-backdrop fade"></div>
				</div>
			</div>

			<div className="layout-overlay layout-menu-toggle"></div>

			<div className="drag-target"></div>
		</div>
	)
}

export default DashboardLayout;