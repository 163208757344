import '../assets/css/roundedFileInput.css'

const RoundedFileInput = ({avatar, picture, formData, setFormData, indicator}) => {
    return (
        <label>
            <div className="avatar avatar-xl my-4 position-relative">
                {
                    indicator && (
                        <span class="avatar-initial bg-primary rounded-circle">N/A</span>
                    )
                }

                {
                    !indicator && !picture && (
                        <span className="avatar-initial bg-primary rounded-circle">{avatar}</span>
                    )
                }

                {
                    !indicator && picture && (
                        <img src={picture} alt="profile" className="avatar-initial rounded-circle" />
                    )
                }
                
            </div>
            {
                formData?.picture?.name  && (
                    <span>Selected file name {formData?.picture.name}</span>
                )
            }
        </label>
    )
}

export default RoundedFileInput